import * as aj from "../aj/index";
import {SETUP_CUSTOM_MENU, SETUP_MENU, SETUP_TOP_MENU} from "./types";
import menu from "../model/menu/menu";
import topMenu from "../model/menu/topMenu";
import { isNotEmpty } from "../framework/utils";
import customMenus from "../model/menu/customMenus";

export const setupMenu = aj.createAction(SETUP_MENU, data => {
    aj.dispatch({
        type: SETUP_MENU,
        menu: menu
    })
})

export const setupCustomMenu = aj.createAction(SETUP_CUSTOM_MENU, data => {
    const menu = customMenus[data.menu];

    if (isNotEmpty(menu)) {
        aj.dispatch({
            type: SETUP_CUSTOM_MENU,
            menu: menu,
        })
    }
})

export const setupTopMenu = aj.createAction(SETUP_TOP_MENU, data => {
    aj.dispatch({
        type: SETUP_TOP_MENU,
        menu: topMenu,
    })
})