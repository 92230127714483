"use strict"
import * as aj from "../aj/index"
import { optional } from "../utils/lang"
import { CLEAN_DIALOG_QUEUE, HIDE_DIALOG, ON_DIALOG_HIDDEN, SHOW_DIALOG, UPDATE_DIALOG } from "./types"

export const hideDialog = aj.createAction(HIDE_DIALOG, () => {
    aj.dispatch({
        type: HIDE_DIALOG,
    })
})

export const onDialogHidden = aj.createAction(ON_DIALOG_HIDDEN, () => {
    aj.dispatch({
        type: ON_DIALOG_HIDDEN,
    })
})

export const showDialog = aj.createAction(SHOW_DIALOG, (options) => {
    aj.dispatch({
        type: SHOW_DIALOG,
        options: optional(options, {}),
    })
})

export const updateDialog = aj.createAction(UPDATE_DIALOG, (options) => {
    aj.dispatch({
        type: UPDATE_DIALOG,
        options: optional(options, {}),
    })
})

export const cleanDialogQueue = aj.createAction(CLEAN_DIALOG_QUEUE, () => {
    aj.dispatch({
        type: CLEAN_DIALOG_QUEUE
    })
})