import * as config from "../framework/config";
import deepmerge from "deepmerge";
import { getSessionToken } from "../api/session";
"use strict";

function getBaseUrl(url) {
    const fsUrl = config.get("fs.url");
    let sanitizedUrl = url;
    
    if (fsUrl.endsWith("/") && url.startsWith("/")) {
        sanitizedUrl = url.substring(1);
    }

    return fsUrl + "/" + sanitizedUrl
}

exports.isEmpty = function(obj) {
    if (obj == undefined) { return true; }
    if (obj == null) { return true; }

    if (obj instanceof Array) {
        return obj.length == 0;
    }

    if (obj == "") {
        return true;
    }

    return false;
};

exports.isNotEmpty = function(obj) {
    return !exports.isEmpty(obj);
};

exports.deepClone = function(obj) {
    return deepmerge({}, obj);
}

exports.getImageServerUrl = function(path, size = null) {
    const baseUrl = getBaseUrl("image");
    let url = baseUrl + "?path=" + encodeURIComponent(path);

    if (size) {
        url += "&size=" + encodeURIComponent(size);
    }

    url += "&__TOKEN=" + encodeURIComponent(getSessionToken())

    return url;
}

exports.getFileServerUrl = function(path) {
    const baseUrl = getBaseUrl("file");
    let url = baseUrl + "?path=" + encodeURIComponent(path);

    url += "&__TOKEN=" + encodeURIComponent(getSessionToken())

    return url;
}