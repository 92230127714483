import React from "react";
import {optional} from "../../utils/lang";
import {isEnter} from "../utils/keyboard";
import * as ui from "../utils/ui";
import _ from "underscore";
import ReactDOM from "react-dom";
import { isNotEmpty } from "../../framework/utils";
import { Actions } from "./actions";

export class ComponentWithTooltips extends React.Component {

    constructor(props) {
        super(props);
    }

    initTooltip() {
        _.each(this.getAllTooltipItems(), i => $(i).tooltip({trigger: "hover"}))
    }

    getAllTooltipItems() {
        const me = ReactDOM.findDOMNode(this)
        let items = []
        $(me).find("[data-toggle=\"tooltip\"]").each(function() {
            items.push(this);
        })
        return items;
    }

    destroyAllTooltips() {
        _.each(this.getAllTooltipItems(), i => $(i).tooltip("dispose"))
    }

    componentDidMount() {
        this.initTooltip()
    }

    componentDidUpdate() {
        this.destroyAllTooltips();
        this.initTooltip()
    }

    componentWillUnmount() {
        this.destroyAllTooltips()
    }
}

export class Card extends React.Component {
    render() {
        let actionKey = 1
        let cardClass = optional(this.props.className, "card");
        let bodyClass = "card-body"
        if (this.props.padding) {
            bodyClass += " card-padding"
        }
        let titleClass = "card-title"
        if (this.props.inverseHeader) {
            titleClass += " card-title-inverse"
        }
        let subtitleClass = "card-subtitle"
        if (this.props.inverseHeader) {
            subtitleClass += " card-subtitle-inverse"
        }
        return (
            <div className={cardClass}>
                <div className={bodyClass}>
                    {!_.isEmpty(this.props.title) &&
                        <h4 className={titleClass}>{this.props.title}</h4>
                    }

                    {!_.isEmpty(this.props.subtitle) &&
                        <h6 className={subtitleClass}>{this.props.subtitle}</h6>
                    }

                    {!_.isEmpty(this.props.actions) &&
                        <Actions actions={this.props.actions} />
                    }

                    {this.props.children}
                </div>
            </div>
        )
    }
}

export class FloatingButton extends React.Component {
    onClick() {
        if (_.isFunction(this.props.onClick)) {
            this.props.onClick()
        }
    }

    render() {
        return (
            <button type="button" className="btn btn--action btn-primary" onClick={this.onClick.bind(this)}><i className={this.props.icon}></i></button>
        )
    }
}

export class EditableText extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            editing: _.isEmpty(props.value),
            value: props.value
        }
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            editing: _.isEmpty(newProps.value),
            value: newProps.value
        })
    }

    onBlur() {
        this.setState({editing: false, value: this.state.lastValue})
    }

    onValueChange(e) {
        e.preventDefault()
        e.stopPropagation()

        this.setState(_.assign(this.state, {editing: true, value: e.target.value}))
    }

    onKeyDown(e) {
        if (isEnter(e.which)) {
            e.preventDefault()
            e.stopPropagation()

            this.setState(_.assign(this.state, {editing: false, lastValue: this.state.value}))

            if (_.isFunction(this.props.onChange)) {
                this.props.onChange(this.state.value)
            }
        }
    }

    edit() {
        this.setState(_.assign(this.state, {editing: true, lastValue: this.state.value}))
    }

    render() {
        let className = this.props.className
        return (
            (this.state.editing || _.isEmpty(this.state.value))  ?
                <div className={"fg-line editable-text " + optional(className, "")}>
                    <input
                        ref="name"
                        type="text"
                        className="form-control"
                        onKeyDown={this.onKeyDown.bind(this)}
                        onChange={this.onValueChange.bind(this)}
                        value={optional(this.state.value, "")}
                        placeholder={this.props.placeholder}
                        autoFocus="autoFocus"
                        onBlur={this.onBlur.bind(this)}/>
                </div>
                :
                <span className={optional(className, "")} onClick={this.edit.bind(this)}>{this.state.value}</span>
        )
    }
}

export class HeaderBlock extends React.Component {
    render() {
        const {title, subtitle, actions, className} = this.props;
        let _className = "header-block";

        if (isNotEmpty(className)) {
            _className += " " + className;
        }

        return (
            <header className={_className}>
                <div
                    className="header-block__text"
                >
                    {(!_.isEmpty(title)) &&
                        <div
                            className="header-block__title"
                        >
                            {title}
                        </div>
                    }

                    {!_.isEmpty(subtitle) &&
                        <div
                            className="header-block__subtitle"
                        >
                            {subtitle}
                        </div>
                    }
                </div>

                {(!_.isEmpty(actions)) &&
                    <div
                        className="header-block__actions"
                    >
                        <Actions actions={actions} />
                    </div>
                }
            </header>
        )
    }
}

export class HeaderBlockWithBreadcrumbs extends React.Component {
    render() {

        let title;
        if (_.isArray(this.props.title)) {
            title = this.props.title.map((item, i) => <BreadcrumbItem key={i} title={item.title} url={item.url} first={i == 0} last={i < this.props.title.length -1} />);

        } else {
            title = <span dangerouslySetInnerHTML={{__html: this.props.title}}></span>
        }

        return (
            <HeaderBlock {...this.props} title={title}/>
        )
    }
}

class BreadcrumbItem extends React.Component {
    constructor(props) {
        super(props)
    }

    onClick() {
        if (this.props.url) {
            ui.navigate(this.props.url)
        }
    }

    render() {
        

        let style = { marginLeft:  !this.props.first ? "10px" : "px"}
        if (this.props.url)
            style.cursor = "pointer";

        let iconStyle= {
            marginLeft: "10px"
        }
        return (
            <span onClick={this.onClick.bind(this)} style={style}>
                <span dangerouslySetInnerHTML={{__html: this.props.title}}></span>
                {this.props.last && <i style={iconStyle} className="zmdi zmdi-caret-right"/>}
            </span>
        )
    }


}
