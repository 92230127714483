import * as _ from "underscore";

let language = "it"

let languages = [
    "it",
    "en",
];

let strings = {}
// strings["en"] = {
// }

strings["it"] = {
    accountConfirmed: "Il tuo account è confermato. Puoi effettuare l'accesso ora",
    accountConfirmText: "Inserisci il codice di attivazione che abbiamo inviato alla tua casella mail per confermare il tuo account",
    accountRecovered: "Una nuova password è stata inviata a {0}",
    accountRecoverText: "Per favore inserisci il tuo indirizzo email per recuperare la password. Ti invieremo una nuova password al tuo indirizzo mail!",
    activationCodeRequired: "Codice di attivazione richiesto",
    active: "Attivo",
    address: "Indirizzo",
    add: "Aggiungi",
    adultDateRequired: "Devi essere maggiorenne",
    alreadyRegistered: "Sei già registrato? Login",
    appName: "Cov-ID",
    articlesListDescription: "Gli articoli devono essere presenti sul sistema Gamma. In commodo gli articoli vengono estesi per aggiungere funzionalità richiesta solo a Commodo",
    articles: "Articoli",
    article: "Articolo",
    autogenerated: "Autogenerato",
    back: "Indietro",
    badLogin: "Non riesco ad accedere! Per favore controlla il tuo indirizzo email o password!",
    cancel: "Annulla",
    categories: "Categorie",
    changePasswordDescription: "Devi impostare una nuova password prima di poter continuare",
    city: "Città",
    close: "Chiudi",
    codeValidationText: "Per favore inserisci il il codice di verifica che hai ricevuto",
    code: "Codice",
    colors: "Colori",
    color: "Colore",
    companies: "Aziende",
    companyName: "Nome dell'Azienda",
    company: "Azienda",
    component: "Componente",
    compositions: "Composizioni",
    confirm: "Conferma",
    congratulations: "Congratulazioni",
    continue: "Continuare",
    conversionFactor: "Fattore di conversione",
    countries: "Paesi",
    country: "Paese",
    cover: "Rivestimenti",
    create: "Crea",
    currentPassword: "Password attuale",
    customers: "Clienti",
    customer: "Cliente",
    dateCanBeforeToday: "La data deve essere <= a quella odierna",
    dateFormat: "DD/MM/YYYY",
    dateTimeFormat: "DD/MM/YYYY HH:mm:ss",
    defaultTime: "Tempo predefinito",
    deferred: "differito",
    delete: "Rimuovi",
    description: "Descrizione",
    design: "Design",
    destinationCountry: "Paese di destinazione",
    duplicate: "Duplica",
    editedBy: "Modificato da",
    editRoleDescription: "Usa un ruolo per gestire cosa può fare un utente nel sistema",
    editRole: "Modifica ruolo",
    editUserDescription: "Usa questo modulo per modificare le informazioni dell'utente",
    editUser: "Modifica utente",
    edit: "Modifica",
    enterDate: "Inserire una data valida",
    enterDecimal: "Inserire un numero decimale",
    enterFiscalCode: "Inserire un codice fiscale valido",
    enterInteger: "Inserire un numero intero",
    enterMail: "Inserisci e-mail",
    enterNumberMax: "Inserire un numero >= di {0}",
    enterNumberMin: "Inserire un numero <= di {0}",
    enterNumberRange: "Inserire un numero >= di {0} e <= di {1}",
    enterNumber: "Inserire un numero",
    entityDeleteConfirm: "Sei sicuro di voler eliminare {0} entità?",
    extraSize: "Extra size",
    familyMember: "Familiare",
    filters: "Filtri",
    filterType: "Tipo di filtro",
    FILTER_AND: "and",
    FILTER_EQ: "uguale a",
    FILTER_EXACT: "essattamente uguale a",
    FILTER_GTE: "maggiore o uguale a",
    FILTER_GT: "maggiore di",
    FILTER_ID: "id",
    FILTER_IN: "è presente in",
    FILTER_LIKE: "contenuto in",
    FILTER_LTE: "minore o uguale a",
    FILTER_LT: "minore di",
    FILTER_NE: "diverso da",
    FILTER_NIN: "non è presente in",
    FILTER_OR: "or",
    FILTER_RANGE: "intervallo (da - a)",
    firstName: "Nome",
    fiscalCode: "Codice fiscale",
    forbiddenScreenGenericMessage: "Non hai i permessi per accedere a questa pagina",
    forgotPassword: "Password dimenticata?",
    formChangeAlert: "Tutti i dati non salvati verranno persi. Continuare?",
    generalInformations: "Informazioni generali",
    home: "Home",
    id: "ID",
    image: "Immagine",
    insertValueAndPressEnter: "Inserisci il valore e premi invio oppure clicca su ricerca",
    invalidCharacters: "Caratteri non validi",
    invalidCreditCard: "Carda di credito non valida",
    invalidEmail: "Email non valida",
    invalidIP: "IP non valido",
    invalidNumber: "Inserire un numero",
    invalidUrl: "Url non valido",
    lastName: "Cognome",
    lastname: "Cognome",
    lastUpdate: "Ultimo aggiornamento",
    logoutConfirmMessage: "Sei sicuro di voler uscire?",
    logout: "Logout",
    mailAddress: "Indirizzo mail",
    mailAndPasswordRequired: "Email e password sono richieste",
    mailRequired: "Email è richiesta",
    mail: "Email",
    makeACopy: "Crea una copia",
    models: "Modelli",
    model: "Modello",
    more: "Altro",
    nameMailAndPasswordRequired: "Nome, email e password sono richieste",
    nameOfRole: "Nome del ruolo",
    name: "Nome",
    nElementsSelected: "{0} elementi selezionati",
    newPasswordText: "Inserisci una nuova password",
    noComponentSelected: "Nessun componente selezionato",
    noResults: "Non ci sono risultati con i criteri specificati",
    noSelection: "Nessuna selezione",
    notActive: "Non attivo",
    notes: "Appunti",
    nothingSelected: "Niente selezionato",
    notFoundScreenGenericMessage: "Ooops... Sembra che questa pagina non esista...",
    notRegistered: "Non sei registrato? Registrati",
    no: "No",
    ok: "OK",
    oneElementSelected: "1 elemento selezionato",
    ooops: "Ooops...",
    pagination: "Record da {0} a {1} di {2} totali",
    passwordConfirm: "Conferma password",
    passwordSuccessfulChanged: "Password cambiata con successo",
    password: "Password",
    paymentCode: "Codice di pagamento",
    permissions: "Permessi",
    permissions: "Permessi",
    phases: "Fasi",
    pleaseSpecifyData: "Per favore specifica la data",
    pleaseSpecifyEntity: "Per favore specifica l'entità",
    pleaseSpecifyId: "Per favore specifica il tuo ID",
    pleaseSpecifyQuery: "Per favore specifica la domanda",
    problemOccoured: "C'è un problema",
    profilesListDescription: "Lista dei profili inseriti dall'app. In questa lista sono presenti anche i familiari",
    profiles: "Profili",
    quantity: "Quantità",
    recoveryCodeSent: "Codice di verifica inviato",
    refresh: "Ricarica",
    registering: "Registrazione...",
    register: "Registrati",
    registrationDate: "Data registrazione",
    removeThisPhase: "Rimuovi questa fase",
    remove: "Rimuovi",
    requiredField: "Campo obbligatorio",
    resultNegative: "Negativo al Covid-19",
    resultPositive: "Positivo al Covid-19",
    resultUnknown: "Tampone non effettuato",
    revisions: "Revisioni",
    revision: "Revisione",
    rolesListDescription: "Un ruolo è un'entità che da all'utente l'autorizzazione per fare qualcosa",
    rolesList: "Lista ruoli",
    roles: "Ruoli",
    role: "Ruolo",
    saveAndGoBack: "Salva e torna alla lista",
    saveComplete: "Salvataggio completato",
    save: "Salva",
    searching: "Sto cercando ...",
    search: "Ricerca",
    security: "Sicurezza",
    selectAll: "Seleziona tutto",
    selectedComponent: "Componente selezionato",
    selectFilterType: "Seleziona il tipo di filtro",
    selectPermissions: "Seleziona i premessi per il ruolo",
    select: "Seleziona",
    sendMail: "Invia mail",
    settings: "Impostazioni",
    setup: "Setup",
    shortName: "Nome breve",
    showRevisions: "Mostra revisioni",
    signIn: "Rgistrati",
    state: "Stato",
    status: "Stato",
    stringTooLarge: "Il campo può avere massimo {0} caratteri",
    stringTooSmall: "Il campo deve contenere almeno {0} caratteri",
    submit: "Invia",
    tests: "Tamponi",
    typeForSearching: "Inizia a scrivere per cercare",
    typeValueToSearch: "Tipo di valore da cercare",
    unitOfMeasurement: "Unità di misura",
    usersListDescription: "Creare, modificare o eliminare gli utenti di sistema",
    usersList: "Lista utenti",
    users: "Utenti",
    validationCodeRequired: "Codice di validazione obbligatorio",
    value: "Valore",
    versions: "Versioni",
    version: "Versione",
    welcomeMessage: "Ciao {0}, la tua registrazione è completa.\nUn link per la conferma è stato inviato a {1}.\nPer favore conferma prima di effettuare l'accesso",
    welcome: "Benvenuto",
    workingTime: "Tempo di lavoro",
    yes: "Si",
    zipCode: "CAP",

    // others
    accountActivation: "Attivazione account",
    activationCode: "Codice di attivazione",
    associations: "Associazioni",
    areYouSure: "Sei sicuro?",
    center: "Centro",
    contacts: "Contatti",
    download: "Scarica",
    kilogramsUnit: "[kg]",
    kilometersUnit: "[km]",
    marketing: "Marketing",
    media: "Media",
    metersUnit: "[mt]",
    north: "Nord",
    noItems: "Nessun elemento",
    planning: "Pianificazione",
    selectNoResults: "Nessun risultato per {0}",
    south: "Sud",
    stats: "Statistiche",
    visited: "Visitati",

    // entities
    advice: "Consiglio",
    advices: "Consigli",
    favorite: "Preferito",
    favorites: "Preferiti",
    interestPoint: "Punto di interesse",
    interestPoints: "Punti di interesse",
    interestPointCategories: "Categorie punti interesse",
    interestPointCategory: "Categoria punti interesse",
    news: "Notizie",
    newsCategories: "Categorie notizie",
    newsCategory: "Categoria notizie",
    route: "Percorso",
    routes: "Percorsi",
    routeInterestPoint: "Punto interesse tratta",
    routeInterestPoints: "Punti interesse tratta",
    routeSection: "Tratta",
    routeSections: "Tratte",
    stage: "Tappa",
    stages: "Tappe",
    stamp: "Timbro",
    stamps: "Timbri",
    usefulNumber: "Numero utile",
    usefulNumbers: "Numeri utili",
    user: "Utente",

    // fields
    allInterestPoints: "Tutti i punti interesse",
    allRoutes: "Tutte le tratte",
    allStages: "Tutte le tappe",
    altitude: "Altitudine",
    category: "Categoria",
    co2Savings: "CO2 risparmiata",
    creationDate: "Data creazione",
    deactivationDate: "Data disattivazione",
    distance: "Distanza",
    downhillHeight: "Dislivello salita",
    downhillLength: "Totale discesa",
    event: "Evento",
    eventEndDate: "Data fine evento",
    eventStartDate: "Data inizio evento",
    finishStage: "Tappa di arrivo",
    icon: "Icona",
    galleryImages: "Immagini galleria",
    galleryLinks: "Link galleria",
    geographicZone: "Area geografica",
    gpsTrack: "Traccia GPS",
    lastLoginDate: "Data ultimo login",
    latitude: "Latitudine",
    link: "Link",
    linkLabel: "Etichetta link",
    longitude: "Longitudine",
    marketingConsent: "Consenso marketing",
    maxAltitude: "Altitudine massima",
    minAltitude: "Altitudine minima",
    notifications: "Notifiche",
    notificationsConsent: "Consenso notifiche",
    notificationTitle: "Titolo notifica",
    notificationText: "Testo notifica",
    ordering: "Ordinamento",
    phoneNumber: "Numero di telefono",
    publicationDate: "Data pubblicazione",
    sex: "Sesso",
    startStage: "Tappa di partenza",
    title: "Titolo",
    text: "Testo",
    type: "Tipo",
    uphillHeight: "Dislivello salita",
    uphillLength: "Totale salita",
    username: "Username",

    generalSettings: "Impostazioni Generali",
    shownAsList: "Visualizzazione a lista?",
    itemsCategory: "Categoria degli Elementi",
    objectTitle: "Titolo",

    addElementsToRouteItemCategory: "Aggiungi Elementi alla Rubrica",

    routeItemCategory: "Rubrica",
    routeItemCategories: "Rubriche",

    routeItemCategory_routeSection: "Scegli le Tratte da aggiungere: ",
    routeItemCategory_interestPoint: "Scegli i Punti di Interesse da aggiungere: ",
    isList: "Lista",

    alternateIcon: "Icona alternativa", 

}

export function setLanguage(language_) {
    language = language_
}

export function getLanguage() {
    return language
}

export function setLanguages(_languages) {
    languages = _languages;
}

export function getLanguages() {
    return languages;
}

export function hasLabel(key) {
    if (strings[language] && strings[language][key]) {
        return true
    } else
        return false;
}

export default function M(key) {

    if (!_.isArray(key)) {
        if (strings[language] && strings[language][key]) {
            return strings[language][key]
        } else {
            logger.w("String not found for language " + language + ":", key)
            return key
        }
    } else
        return _.map(key, a => M(a)).join(" ")


}