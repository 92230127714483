import React from "react";
import _ from "underscore";
import { uuid } from "../../../utils/lang";
import { Actions, ACTION_TYPES, DEFAULT_ACTIONS } from "../actions";
import { HeaderBlock } from "../common";
import { Control } from "../forms";
import { isEmpty } from "../../../framework/utils";
import M from "../../../strings";

export default class ListControl extends Control {

    constructor(props) {
        super(props);
        this.uuid = uuid();
    }

    getValue() {
        return super.getValue() ?? [];
    }

    canAdd() {
        return !_.any(this.getValue(), v => isEmpty(v));
    }

    onAdd() {
        const value = [...this.getValue()];

        value.push(undefined);

        this.onValueChange({
            target: {
                value: value,
            }
        })
    }

    onDelete(index) {
        const newValue = [];

        _.each(this.getValue(), (v,i) => {
            if (i !== index) {
                newValue.push(v);
            }
        })

        this.onValueChange({
            target: {
                value: newValue,
            }
        })
    }

    onItemChange(index, e) {
        e && e.preventDefault();

        const itemValue = e.currentTarget.value,
            value = [...this.getValue()];

        value[index] = itemValue;

        this.onValueChange({
            target: {
                value: value,
            }
        });
    }

    getActions() {
        return [
            {
                id: DEFAULT_ACTIONS.CREATE,
                icon: "add",
                type: ACTION_TYPES.ICON,
                action: this.onAdd.bind(this),
                disabled: !this.canAdd(),
            },
        ];
    }

    render() {
        const title = this.props.title,
            actions = this.getActions(),
            value = this.getValue(),
            inputType = this.props.inputType ?? "text",
            placeholder = this.props.placeholder || this.props.field.placeholder || this.props.field.label || this.props.field.proerty;

        return(<div className="list-control">
            <HeaderBlock
                subtitle={title}
                actions={actions}
            />
            {_.map(value, (v, index) => {
                const actions = [
                    {
                        id: DEFAULT_ACTIONS.DELETE,
                        type: ACTION_TYPES.ICON,
                        icon: "delete",
                        action: this.onDelete.bind(this, index),
                    }
                ];

                return (<div
                    key={"list-control-" + this.uuid + "-item-" + index}
                    className="list-control__item"
                >
                    <div
                        className="form-group"
                    >
                        <input
                            type={inputType}
                            className="form-control"
                            placeholder={placeholder}
                            value={v}
                            onChange={this.onItemChange.bind(this, index)}
                        />
                        <i
                            className="form-group__bar"
                        />
                    </div>
                    <Actions
                        actions={actions}
                    />
                </div>)
            })}
            {isEmpty(value) && <div
                className="m-3 text-center font-weight-light"
            >
                {M("noItems")}
            </div>}
        </div>)
    }
}