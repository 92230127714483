import React from "react";
import AbstractEntitiesGrid from "./abstractEntitiesGrid";
import withCustomMenu from "../../components/customMenu";

class SubsectionGrid extends AbstractEntitiesGrid {
    getRoot() {
        return this.props.root ?? super.getRoot();
    }

    getPage() {
        return this.props.page ?? super.getPage();
    }
}

export default withCustomMenu(SubsectionGrid)