import M from "../strings";
import _ from "underscore"
import * as datasource from "../utils/datasource";


export const TextFormat = {
    SIMPLE_TEXT: {
        value: 0,
        label: M("simpleText"),
    },
    ADVANCED_TEXT: {
        value: 1,
        label: M("advancedText"),
    },
    HTML: {
        value: 2,
        label: M("html"),
    },
};

export const TextFormatDatasource = datasource.fixed(
    _.map(TextFormat,f=>f)
);

export const PAGES = {
    HOME: "home",
    ROUTE: "route",
    NEWS: "news",
    FAVORITES: "favorites",
    VISITED: "visited",
    SETTINGS: "settings",
}

export const STAMP_TYPES = {
    STAGE: "stage",
    INTEREST_POINT: "interestPoint",
}