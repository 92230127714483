import "@babel/polyfill";
import "./aj/polyfill.web";
import { setLanguage } from "./strings";

const handleLocalizations = function () {
    return new Promise((resolve, reject) => {
        // we can use this to set localizations with setLanguage before we load the app
        // this ensures that every call to strings.M uses the correct localization from now on
        resolve();
    })
}

handleLocalizations()
    .then(() => {
        return import("./web/main")
    })
    .then(module => {
        const main = module.default;
        main();
    });