import React from "react";
import _ from "underscore";
import { isNotEmpty } from "../../framework/utils";
import { setupCustomMenu, setupMenu } from "../../actions/menu";


export default function withCustomMenu(Component, menu) {
    return class WrappedComponent extends React.Component {
        componentDidMount() {
            const menu = this.props.menu ?? menu;
            if (isNotEmpty(menu)) {
                setupCustomMenu({
                    menu: menu,
                });
            }
        }
    
        componentWillUnmount() {
            const menu = this.props.menu;
            if (isNotEmpty(menu)) {
                setupMenu();
            }
        }

        render() {
            return <Component {..._.omit(this.props, "menu")}/>
        }
    }
}