import { secureScreen } from "../../components/secure";
import _EntitiesGrid from "./entitiesGrid";
import _EntityForm from "./entityForm";
import _RevisionGrid from "./revisionsGrid";
import _SubsectionGrid from "./subsectionGrid";
import _SubsectionForm from "./subsectionForm";
import permissions from "../../../model/permissions";

const _permissions = [permissions.ADMIN_SUPERUSER];

export const EntitiesGrid = secureScreen(_EntitiesGrid, _permissions);
export const EntityForm = secureScreen(_EntityForm, _permissions);
export const RevisionGrid = secureScreen(_RevisionGrid, _permissions);
export const SubsectionGrid = secureScreen(_SubsectionGrid, _permissions);
export const SubsectionForm = secureScreen(_SubsectionForm, _permissions);