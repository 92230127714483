import React, { useCallback, useMemo } from "react";
import _ from "underscore";
import { Control } from "../forms";
import { deepClone } from "../../../framework/utils";
import { format, uuid } from "../../../utils/lang";
import { sanitizedLocalizedObject } from "../../utils/localizedObject";
import JoditEditor from "jodit-react";
import M from "../../../strings";

function withLocalizedControl(Component) {
    return class LocalizedControl extends Control {
        constructor(props) {
            super(props);
            this.uuid = uuid();
        }
    
        getValue() {
            return sanitizedLocalizedObject(super.getValue());
        }

        onValueChange(lang, e) {
            const value = deepClone(this.getValue()),
                localizedValues = value.values ?? [];
            let editedValue = _.findWhere(localizedValues, {lang: lang});

            if (editedValue === undefined) {
                editedValue = {
                    lang: lang,
                };
                localizedValues.push(editedValue);
            }
            
            editedValue.value = e?.target?.value ?? e;
            
            super.onValueChange({
                target: {
                    value: value,
                }
            });
        }

        render() {
            const values = this.getValue().values;
            return <>
                {_.map(values, (v,index) => {
                    const key = format("{0}_{1}", this.uuid, index),
                        lang = v.lang;
                    return <Component
                        key={key}
                        {...this.props}
                        {...v}
                        onChange={this.onValueChange.bind(this, lang)}
                    />
                })}
            </>
        }
    }
}

function _LocalizedText(props) {
    const {value, lang, field} = props,
        placeholder = field?.placeholder,
        onChange = useCallback((...args) => {
            if (_.isFunction(props.onChange)) {
                props.onChange(...args);
            }
        }, [props.onChange])

    return <div
        className="input-group form-group m-b-8"
    >
        <div
            className="input-group-prepend"
        >
            <span
                className="input-group-text text-uppercase font-weight-bold"
            >
                {lang}
            </span>
        </div>
        <input
            type="text"
            className="form-control"
            placeholder={placeholder}
            value={value}
            onChange={onChange}
        />
        <i
            className="form-group__bar"
        />
    </div>
}

function _LocalizedTextArea(props) {
    const {value, lang, field, rows = 5} = props,
        placeholder = field?.placeholder,
        onChange = useCallback((...args) => {
            if (_.isFunction(props.onChange)) {
                props.onChange(...args);
            }
        }, [props.onChange]);

        return <div
        className="input-group form-group m-b-8"
    >
        <div
            className="input-group-prepend"
        >
            <span
                className="input-group-text text-uppercase font-weight-bold"
            >
                {lang}
            </span>
        </div>
        <textarea
            className="form-control"
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            rows={rows}
        />
        <i
            className="form-group__bar"
        />
    </div>
}

function _AdvancedLocalizedTextControl(props){
    const {field, options, value, lang} = props,
        {label, placeholder, property} = field ?? {},
        onChange = useCallback((...args) => {
            if(_.isFunction(props.onChange)){
                props.onChange(...args);
            }
        }, [props.onChange]),
        config = useMemo(() => {
            return _.extend(
                {
                    placeholder: placeholder || label || M(property),
                    hidePoweredByJodit: true,
                    askBeforePasteFromWord: false,
                    processPasteFromWord: false,
                    askBeforePasteHTML: false,
                    processPasteHTML: false,
                },
                options ?? {},
            );
        }, [property, label, placeholder, options]);
        

        return <div
            className="input-group form-group m-b-8 flex-nowrap"
        >
        <div
            className="input-group-prepend flex-grow-0 flex-shrink-0"
        >
            <span
                className="input-group-text text-uppercase font-weight-bold"
            >
                {lang}
            </span>
        </div>
        <div
            className="flex-grow-1 flex-shrink-1"
        >
            <JoditEditor
                value = {value}
                config = {config}
                onChange = {onChange}
            />
        </div>
        <i
            className="form-group__bar"
        />
    </div>
}


export const LocalizedTextArea = withLocalizedControl(_LocalizedTextArea);
export const LocalizedText = withLocalizedControl(_LocalizedText);
export const AdvancedLocalizedTextControl = withLocalizedControl(_AdvancedLocalizedTextControl);