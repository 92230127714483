import React from "react";
import { PAGES } from "../../model/vars";
import { Screen, Layout } from "../components/layout";
import { secureScreen } from "../components/secure";
import withCustomMenu from "../components/customMenu";
import permissions from "../../model/permissions";

class _Settings extends Screen {

    render() {
        const page = this.props.page ?? PAGES.SETTINGS;

        return(
            <Layout
                page={page}
            >

            </Layout>
        )
    }
}

export default secureScreen(withCustomMenu(_Settings), [permissions.ADMIN_SUPERUSER]);