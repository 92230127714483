import _ from "underscore";
import React from "react";
import {Layout, LayoutNoMenu, Screen} from "../../components/layout";
import M from "../../../strings";
import {deleteEntities, freeEntities, loadEntities, updateQuery} from "../../../actions/entities";
import {FloatingButton, HeaderBlock} from "../../components/common";
import {Grid, resultToGridData} from "../../components/grids";
import * as query from "../../../framework/query";
import {format, optional, parseBoolean} from "../../../utils/lang";
import * as ui from "../../utils/ui";
import {Permission} from "../../../api/session";
import {discriminated} from "../../../utils/ajex";
import {connectDiscriminated} from "../../../utils/aj-react";
import {EntitiesStore, SearchStore} from "../../../stores/entities";
import { getEntityData } from "../../entities";
import { PAGES } from "../../../model/vars";
import { ACTION_TYPES, ActionsMatcher } from "../../components/actions";
import { confirm } from "../../../plugins";

class AbstractEntitiesGrid extends Screen {
    constructor(props) {
        super(props)

        if (_.isEmpty(this.getEntity())) {
            throw new Error("Please specify entity for form")
        }
        this.discriminator = "entity_grid_" + this.getEntity()
        let _query = this.getInitialQuery();
        this.state = {grid: null, result: null, query: _query}
        this.state.query.on("change", () => {
            updateQuery({discriminator: this.discriminator, query: this.state.query});
            this.onQueryChanged()
        })

        connectDiscriminated(this.discriminator, this, [EntitiesStore])
    }


    getInitialQuery() {

        //TODO: non bellisimo: ricorrere alla action getQuery
        let state = optional(discriminated(optional(SearchStore.state, {}), this.discriminator), {});
        let _query;
        if (state ) {
            _query = state.query;
        }

        if (!_query)
            _query = this.getEntityGrid().initialQuery


        if (_.isFunction(this.getEntityGrid().initialQuery)) {
            _query = this.getEntityGrid().initialQuery()
        }
        if (!_query) {
            _query = query.create()
            _query.page = 1
            _query.rowsPerPage = 50
        }

        return _query;
    }


    getEntity() {
        return this.props.entity
    }

    getEntityGrid() {
        const entity = this.getEntity(),
            descriptorData = getEntityData(entity),
            entityGrid = descriptorData.grid;
        return entityGrid
    }

    componentDidMount() {
        loadEntities({discriminator: this.discriminator, entity: this.getEntity(), query: this.state.query})
    }

    componentWillUnmount() {
        freeEntities({discriminator: this.discriminator})
    }

    onQueryChanged() {
        loadEntities({discriminator: this.discriminator, entity: this.getEntity(), query: this.state.query})
    }

    editEntity(data) {
        if (!this.canEdit()) {
            return
        }

        ui.navigate(this.getEditUrl(data))
    }

    createEntity() {
        if (!this.canCreate()) {
            return
        }

        ui.navigate(this.getCreateUrl())
    }

    getRoot() {
        return "entities";
    }

    getCreateUrl() {
        let grid = this.getEntityGrid()
        let createUrl = grid.createUrl
        if (_.isFunction(createUrl)) {
            createUrl = createUrl()
        }
        return optional(createUrl, `/${this.getRoot()}/${this.getEntity()}/new`)
    }

    getEditUrl(data) {
        let grid = this.getEntityGrid()
        if (_.isFunction(grid.editUrl)) {
            return format(grid.editUrl(data))
        } else if (!_.isEmpty(grid.editUrl)) {
            return format(grid.editUrl, data.id)
        } else {
            return `/${this.getRoot()}/${this.getEntity()}/${data.id}`
        }
    }
    getDeleteMessage() {
        let message = format(M("entityDeleteConfirm"), this.refs.grid.getSelection().length)
        let entityMessage = this.getGrid().deleteMessage
        if (entityMessage)
            message = format("{0}\n{1}", message, entityMessage)
        return message;
    }

    deleteEntities() {
        if (!this.canDelete()) {
            return
        }

        let selection = this.refs.grid.getSelection()
        if (_.isEmpty(selection)) {
            return
        }

        confirm(M("confirm"), this.getDeleteMessage())
            .then(() => {
                return deleteEntities({discriminator: this.discriminator, entity: this.getEntity(), ids: selection.map(s => s.id)})
            })
            .catch(e => {
                this.refs.grid.clearSelection();
                this.onQueryChanged();
                console.log(e);
            })
    }

    onGridRowDoubleClick(row) {
        this.editEntity(row)
    }

    getTitle() {
        let grid = this.getEntityGrid()
        return optional(grid.title, "List")
    }

    getSubtitle() {
        let grid = this.getEntityGrid()
        return grid.subtitle
    }

    getActions() {
        let defaultActions = [
            {
                id: "refresh",
                type: ACTION_TYPES.ICON,
                icon: "cached",
                title: M("refresh"),
                permissions: [this.getEntity() + ":" + Permission.LIST],
                action: () => { loadEntities({discriminator: this.discriminator, entity: this.getEntity(), query: this.state.query}) }
            }

        ]

        if(this.canCreate()){
            defaultActions.push(
                {
                    id: "create",
                    type: ACTION_TYPES.ICON,
                    icon: "add_circle",
                    title: M("create"),
                    permissions: [this.getEntity() + ":" + Permission.NEW],
                    action: () => { this.createEntity() }
                },
            )
        }

        if(this.canDelete()){
            defaultActions.push({
                id: "delete",
                type: ACTION_TYPES.ICON,
                icon: "delete",
                title: M("delete"),
                permissions: [this.getEntity() + ":" + Permission.DELETE],
                action: () => { this.deleteEntities() }
            })
        }

        defaultActions.push({
            id: "selectAll",
            type: ACTION_TYPES.ICON,
            icon: "select_all",
            title: M("selectAll"),
            action: () => { this.refs.grid.toggleSelectAll() }
        });

        let grid = this.getEntityGrid()
        let matcher = new ActionsMatcher(defaultActions)
        let actions = matcher.match(_.isFunction(grid.getActions) ? grid.getActions()  : grid.actions);

        //grouping non-create actions, if any
        const dropdownActions = _.reject(actions, a => a.id === "create")
        if (dropdownActions.length > 1) {
            actions = _.where(defaultActions, {id: "create"});
            actions.push({
                id: "more",
                type: ACTION_TYPES.DROPDOWN,
                items: dropdownActions,
            })
        }

        return actions;
    }

    getGrid() {
        return this.refs.grid
    }

    getDescriptor() {
        let grid = this.getEntityGrid()
        return grid.descriptor
    }

    getData() {
        return resultToGridData(this.state.result)
    }

    isQuickSearchEnabled() {
        let grid = this.getEntityGrid()
        return optional(grid.quickSearchEnabled, false)
    }

    getQuickSearchPlaceholder() {
        let grid = this.getEntityGrid()
        return optional(grid.quickSearchPlaceholder, "")
    }

    getHeaderVisibleNoResults() {
        let grid = this.getEntityGrid()
        return optional(grid.headerVisibleNoResults, false)
    }

    canEdit() {
        let grid = this.getEntityGrid()
        return optional(grid.canEdit, true)
    }

    canCreate() {
        let grid = this.getEntityGrid()
        return optional(grid.canCreate, true)
    }

    canDelete() {
        let grid = this.getEntityGrid()
        return optional(grid.canDelete, true)
    }

    hideFilters() {
        return false;
    }


    selectWithCheck() {
        return optional(this.props.selectWithCheck, false)
    }

    generateHeaderBlock() {

        let title = this.getTitle()
        let subtitle = this.getSubtitle()
        let actions = this.getActions()
        return (<HeaderBlock
            className="entity-grid__subheader entity-grid__subheader--fixed"
                title={title}
                subtitle={subtitle}
                actions={actions}
            />)
    }

    renderExtra() {
        return null
    }

    getPage() {
        return PAGES.SETTINGS;
    }

    render() {
        const descriptor = this.getDescriptor(),
            data = this.getData(),
            header = this.generateHeaderBlock(),
            page = this.getPage(),
            noMenu = parseBoolean(this.props.noMenu, false),
            LayoutComponent = noMenu ? LayoutNoMenu : Layout;

        return (
            <LayoutComponent
                page={page}
                activeMenuItem={this.getEntity()}
            >
                {header}
                <Grid
                    ref="grid"
                    descriptor={descriptor}
                    discriminator={this.discriminator}
                    data={data}
                    query={this.state.query}
                    hideFilters={this.hideFilters()}
                    onRowDoubleClick={this.onGridRowDoubleClick.bind(this)}
                    quickSearchEnabled={this.isQuickSearchEnabled()}
                    quickSearchPlaceholder={this.getQuickSearchPlaceholder()}
                    headerVisibleNoResults={this.getHeaderVisibleNoResults()}
                    selectWithCheck={this.selectWithCheck()}
                />

                {this.renderExtra()}

                {this.canCreate() &&
                    <FloatingButton icon="zmdi zmdi-plus" onClick={this.createEntity.bind(this)} />
                }
            </LayoutComponent>
        )
    }
}

export default AbstractEntitiesGrid