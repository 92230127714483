import _ from "underscore";
import React from "react";
import {Layout, LayoutNoMenu, Screen} from "../../components/layout"
import M from "../../../strings"
import {connectDiscriminated} from "../../utils/aj"
import {checkRevisionEnableStatus, freeEntities, getEntity, saveEntity} from "../../../actions/entities"
import {Form} from "../../components/forms"
import * as ui from "../../utils/ui"
import {optional, parseBoolean} from "../../../utils/lang"
import {EntitiesStore} from "../../../stores/entities";
import { HeaderBlockWithBreadcrumbs } from "../../components/common";
import { ActionsMatcher, ACTION_TYPES } from "../../components/actions";
import {hasPermission, Permission} from "../../../api/session";
import { getEntityData } from "../../entities";
import { PAGES } from "../../../model/vars";
import { isEmpty } from "../../../framework/utils";
import traverse from "../../../utils/traverse";
import { getLocalizedValue, isLocalizedObject } from "../../utils/localizedObject";

class EntityForm extends Screen {
    constructor(props) {
        super(props)

        if (_.isEmpty(props.entity)) {
            throw new Error("Please specify entity for form")
        }

        this.discriminator = "entity_form_" + props.entity
        this.initialEntity = null
        this.willGoBack = true

        connectDiscriminated(this.discriminator, this, EntitiesStore, {data: null})
    }

    componentDidMount() {
        let form = this.refs.form
        let model = form.model

        this.onBeforeUnload = function() {
            if (model.hasChanges()) {
                return M("formChangeAlert")
            }
        }

        //window.onbeforeunload = this.onBeforeUnload
        //ui.addOnBeforeChangeListener(this.onBeforeUnload)

        this.setState({isCreation: this.props.entityId == "new"});
        getEntity({discriminator: this.discriminator, entity: this.props.entity, id: this.props.entityId, params: this.props.params})
        checkRevisionEnableStatus({discriminator: this.discriminator, entity: this.props.entity})
    }

    goToRevision() {
        ui.navigate("/revision/" + this.props.entity + "/" + this.getEntityId())
    }


    getEntityId() {
        let id = this.state.data != null? this.state.data.id : null
        if (!id) {
            if (this.props.entityId !== "new" && this.props.entityId !== "_")
                id = this.props.entityId
        }
        return id;
    }
    componentWillUnmount() {
        freeEntities({discriminator: this.discriminator})

        window.onbeforeunload = null
        ui.removeOnBeforeChangeListener(this.onBeforeUnload)
    }

    submit(goBack) {
        this.willGoBack = goBack
        this.refs.form.submit()
    }

    onSubmit(data) {
        if (_.isFunction(this.props.onSubmit)) {
            this.props.onSubmit(data)
        } else {
            saveEntity({discriminator: this.discriminator, entity: this.props.entity, data: data, reload: !this.willGoBack})    
        }        
    }

    onCancel() {
        this.goBack()
    }

    goBack() {
        const form = this.refs.form
        const data = form.model.sanitized()
        ui.navigate(this.getGridUrl(data))
    }

    componentWillUpdate(props, state) {
        if (state.saved) {
            this.refs.form.model.reset()
        }

        if (state.saved && this.willGoBack) {
            this.goBack()
            return false
        }

        if (state.validationError) {
            if (state.validationResult) {
                let form = this.refs.form
                if (form && form.model) { 
                    _.each(state.validationResult.errors, e => {
                        form.model.setError(e.property, M(e.message))
                    })
                }
            }
            this.refs.form.model.invalidateForm()
        }

        if (state.loaded && !this.initialized) {
            this.onDataLoad(state.data)
            this.initialized = true;
        }
    }

     onDataLoad(data) {
        let form = this.getEntityForm()
        if (_.isFunction(form.onDataLoad)) {
            form.onDataLoad(data, this.props.params);
        }
    }

    getEntity() {
        return this.props.entity
    }

    getEntityForm() {
        const entity = this.getEntity(),
            descriptorData = getEntityData(entity),
            entityForm = descriptorData.form;
        return entityForm
    }

    getEntityGrid() {
        const entity = this.getEntity(),
            descriptorData = getEntityData(entity),
            entityGrid = descriptorData.grid;
        return entityGrid
    }

    getRoot() {
        return "entities"
    }

    getGridUrl(data) {
        const form = this.getEntityForm()
        let gridUrl = form.gridUrl
        if (_.isFunction(gridUrl)) {
            gridUrl = gridUrl(data)
        }

        `/${this.getRoot()}/${this.getEntity()}/new`
        return optional(gridUrl, `/${this.getRoot()}/${this.getEntity()}`)
    }

    getActions() {
        let defaultActions = [];

        if(this.canSave()){
            defaultActions.push(
                {
                    id: "save",
                    type: ACTION_TYPES.BUTTON,
                    icon: "save",
                    title: M("save"),
                    permissions: this.getEntitySavePermissions(),
                    action: () => {
                        this.submit(true)
                    }
                },
                /*{
                    id: "save-go-back",
                    type: "icon",
                    icon: "zmdi zmdi-rotate-ccw",
                    tooltip: M("saveAndGoBack"),
                    permissions: this.getEntitySavePermissions(),
                    action: () => {
                        this.submit(true)
                    }
                }*/
            )
        }

        defaultActions.push({
            id: "back",
            type: ACTION_TYPES.ICON,
            icon: "arrow_back",
            title: M("back"),
            action: () => {
                this.goBack()
            }
        });

        if (this.canShowRevisions()) {
            defaultActions.push(
                {
                    id: "revisions",
                    type: ACTION_TYPES.ICON,
                    icon: "restore",
                    title: M("showRevisions"),
                    action: () => {
                        this.goToRevision()
                    }
                }
            )
        }

        let form = this.getEntityForm();
        let matcher = new ActionsMatcher(defaultActions);
        return matcher.match(_.isFunction(form.getActions) ? form.getActions(this.state.data)  : form.actions)
    }

    canShowRevisions() {
        return this.state && this.state.revisionEnabled && this.state.revisionEnabled === true && this.getEntityId();
    }

    canSave() {
        let form = this.getEntityForm()
        return optional(form.canSave, hasPermission(this.getEntitySavePermissions()))
    }

    getEntitySavePermissions() {
        return [this.getEntity() + ":" + Permission.SAVE]
    }

    getEntityListPermissions() {
        return [this.getEntity() + ":" + Permission.LIST]
    }

    getPermittedActions() {
        return _.filter(this.getActions(), a => hasPermission(a.permissions) === true)
    }


    canCancel() {
        let descriptor = this.getDescriptor()
        return _.isFunction(descriptor.canCancel) ? descriptor.canCancel(this.state.data) : true
    }

    getTitle() {
        let entity = this.getEntity()
        let form = this.getEntityForm();
        let title = _.isFunction(form.getTitle) ? form.getTitle(this.state.data, this.props.params): null;

        if (title)
            return title;
        else {
            let items = [];
            let gridTitle = this.getEntityGrid().title;
            let data = this.state && this.state.data ? this.state.data : {}
            items.push({title: gridTitle, url : this.getGridUrl(data)});
            if (data.id && data.id !== "new") {
                items.push({title: M(["edit", entity]) + " " + " <b>" + this.generateDataDescription(data) + "</b>"});
            } else {
                items.push({title: M(["create", entity])});
            }
            return items;
        }
    }

    generateDataDescription(data) {
        if (isEmpty(data)) {
            return "";
        }

        const form = this.getEntityForm(),
            dataDescriptionKey = form.dataDescriptionKey ?? "description",
            dataDescriptionValue = optional(() =>traverse(data).get(dataDescriptionKey), "");

        return  isLocalizedObject(dataDescriptionValue) ? getLocalizedValue(dataDescriptionValue) : dataDescriptionValue;
    }


    getSubtitle() {
        let form = this.getEntityForm()
        return form.subtitle
    }

    getDescriptor() {
        let form = this.getEntityForm()
        return form.descriptor
    }

    getFormComponent() {
        let form = this.getEntityForm()
        return optional(() => form.component, () => Form)
    }

    getPage() {
        return PAGES.SETTINGS;
    }

    render() {
        const title = this.getTitle(),
            subtitle = this.getSubtitle(),
            actions = this.getActions(),
            descriptor = this.getDescriptor(),
            component = this.getFormComponent(),
            selectedTab = this.props.params.selectedTab,
            page = this.getPage(),
            noMenu = parseBoolean(this.props.noMenu, false),
            LayoutComponent = noMenu ? LayoutNoMenu : Layout;


        return (
            <LayoutComponent
                page={page}
                activeMenuItem={this.getEntity()}
            >
                <HeaderBlockWithBreadcrumbs
                    className="entity-form__subheader entity-form__subheader--fixed"
                    title={title}
                    subtitle={subtitle}
                    actions={actions}
                />
                {React.createElement(component, {
                    ref: "form",
                    descriptor: descriptor,
                    data: this.state.data,
                    selectedTab : selectedTab,
                    onSubmit: this.onSubmit.bind(this),
                    onCancel: this.onCancel.bind(this)
                })}
            </LayoutComponent>
        )
    }
}

export default EntityForm