import React from "react";
import EntityForm from "./entityForm";
import withCustomMenu from "../../components/customMenu";

class SubsectionForm extends EntityForm {
    getRoot() {
        return this.props.root ?? super.getRoot();
    }

    getPage() {
        return this.props.page ?? super.getPage();
    }
}

export default withCustomMenu(SubsectionForm)