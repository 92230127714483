import React from "react";
import _ from "underscore";
import {AccountStore} from "../../stores/account";
import {FullScreenLayout, Screen} from "../components/layout";
import * as ui from "../utils/ui";
import * as forms from "../utils/forms";
import M from "../../strings";
import {confirmAccount, setActivationCode} from "../../actions/account";
import {connect} from "../utils/aj";

export default class Recover extends Screen {

    constructor(props) {
        super(props);
        this.state={
            confirmed: false,
        }
    }

    confirm(data) {
        confirmAccount(data)
            .then(() => {
                this.setState({
                    confirmed: true,
                })
            })
    }

    render() {
        const confirmed = this.state.confirmed;
        return (
            <FullScreenLayout>
                <div className="login">
                    <div className="login__block active" id="l-forget-password">
                        <div className="login__block__header palette-Purple bg">
                            <i className="zmdi zmdi-account-circle"></i>
                            {M("accountActivation")}
                        </div>

                        <div className="login__block__body">
                            {confirmed ? (
                                <span>
                                    {M("accountConfirmed")}
                                </span>
                            ) : (
                                <ActivationCodeForm
                                    activationCode={this.props.activationCode}
                                    confirm={this.confirm.bind(this)}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </FullScreenLayout>
        )
    }
}

class ActivationCodeForm extends React.Component {
    constructor(props) {
        super(props)
        connect(this, AccountStore, {activationCode: ""})
    }

    confirm(e) {
        e && e.preventDefault();
        let data = forms.serialize(this.refs.confirm_form)
        if (this.props.confirm && _.isFunction(this.props.confirm)) {
            this.props.confirm(data);
        }
    }

    componentDidMount() {
        setActivationCode({activationCode: this.props.activationCode})
    }

    render() {
        return(
            <form action="" className="lcb-form" onSubmit={this.confirm.bind(this)} ref="confirm_form">
                <p className="text-left">{M("accountConfirmText")}</p>
                <div className="input-group form-group form-group--float form-group--centered">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            <i className="zmdi zmdi-lock"/>
                        </span>
                    </div>
                    <input type="text" name="activationCode" className="form-control" placeholder={M("activationCode")} value={this.state.activationCode} />
                    <i className="form-group__bar"></i>
                </div>
                <button
                    type="submit"
                    className="btn btn-lg btn-primary btn--icon btn--rotating shadow"
                >
                    <i
                        className="zmdi zmdi-lock-open"
                    />
                </button>
            </form>
        )
    }
}
