import React from "react";
import ReactDOM from "react-dom";
import {Index} from "./components/layout";
import Login from "./screens/login";
import Recover from "./screens/recover";
import Confirm from "./screens/confirm";
import * as ui from "./utils/ui";
import * as plugins from "./pluginsimpl";
import {resumeSession} from "../actions/session";
import * as keyboard from "./utils/keyboard";
import {SessionStore} from "../stores/session";
import {EntitiesGrid, EntityForm, RevisionGrid, SubsectionGrid, SubsectionForm} from "./screens/entities";
import { getUserProfileImage } from "../actions/ui";
import { setupMenu, setupTopMenu } from "../actions/menu";
import * as NotificationCenter from "../utils/notificationCenter";
import Settings from "./screens/settings";
import { PAGES } from "../model/vars";
import * as Menus from "../model/menu/types";
import NotFound from "./screens/notFound";


export default function main() {
	/* Register plugins */
	plugins.register()
	
	/* Admin routes */
	ui.addRoute("/entities/:entity", params => ui.changeScreen(<EntitiesGrid key={params.entity} entity={params.entity} />))
	ui.addRoute("/entities/:entity/:entityId", params => ui.changeScreen(<EntityForm key={params.entity} entity={params.entity} entityId={params.entityId} params={params}/>))
	ui.addRoute("/entities/single/:entity", params => ui.changeScreen(<EntityForm key={params.entity} entity={params.entity} entityId="_" params={params}/>))
	ui.addRoute("/revision/:entity/:entityId", params => ui.changeScreen(<RevisionGrid key={params.entity} entityId={params.entityId}  entity={params.entity} params={params} />))
	ui.addRoute("/recover", params => ui.changeScreen(<Recover />))

	ui.addRoute("/route/:entity", params => ui.changeScreen(<SubsectionGrid
		root="route"
		page={PAGES.ROUTE}
		menu={Menus.ROUTE_MENU}
		key={params.entity}
		entity={params.entity}
	/>))
	ui.addRoute("/route/:entity/:entityId", params => ui.changeScreen(<SubsectionForm
		root="route"
		page={PAGES.ROUTE}
		menu={Menus.ROUTE_MENU}
		key={params.entity}
		entity={params.entity}
		entityId={params.entityId}
		params={params}
	/>))

	/*ui.addRoute("/entities/stamp", params => ui.changeScreen(<SubsectionGrid
		key="stamp"
		page={PAGES.VISITED}
		entity="stamp"
		noMenu={true}
	/>))
	ui.addRoute("/entities/stamp/:entityId", params => ui.changeScreen(<SubsectionForm
		key="stamp"
		page={PAGES.VISITED}
		entity="stamp"
		entityId={params.entityId}
		noMenu={true}
		params={params}
	/>))

	ui.addRoute("/entities/favorite", params => ui.changeScreen(<SubsectionGrid
		key="favorite"
		page={PAGES.FAVORITES}
		entity="favorite"
		noMenu={true}
	/>))
	ui.addRoute("/entities/favorite/:entityId", params => ui.changeScreen(<SubsectionForm
		key="favorite"
		page={PAGES.FAVORITES}
		entity="favorite"
		entityId={params.entityId}
		noMenu={true}
		params={params}
	/>))*/

	ui.addRoute("/news/:entity", params => ui.changeScreen(<SubsectionGrid
		root="news"
		page={PAGES.NEWS}
		menu={Menus.NEWS_MENU}
		key={params.entity}
		entity={params.entity}
	/>))
	ui.addRoute("/news/:entity/:entityId", params => ui.changeScreen(<SubsectionForm
		root="news"
		page={PAGES.NEWS}
		menu={Menus.NEWS_MENU}
		key={params.entity}
		entity={params.entity}
		entityId={params.entityId}
		params={params}
	/>))
	
	ui.addRoute("/settings", params => ui.changeScreen(<Settings params={params}/>))
	ui.addRoute("/route", params => ui.changeScreen(<Settings key={PAGES.ROUTE} page={PAGES.ROUTE} menu={Menus.ROUTE_MENU}/>))
	ui.addRoute("/news", params => ui.changeScreen(<Settings key={PAGES.NEWS} page={PAGES.NEWS} menu={Menus.NEWS_MENU}/>))

	/* Account routes */
	ui.addRoute("/login", params => ui.changeScreen(<Login />))
	//ui.addRoute("/register", params => ui.changeScreen(<Register />))
	ui.addRoute("/recover", params => ui.changeScreen(<Recover />))
	//ui.addRoute("/registrationComplete", params => ui.changeScreen(<RegistrationOk />))

	ui.addRoute("/confirm", params => ui.changeScreen(<Confirm activationCode={params.activationCode}/>))

	/* home route */
	//ui.addRoute("/", params => ui.changeScreen(<Home />))
	ui.addRoute("/", params => ui.changeScreen(<Settings key={PAGES.ROUTE} page={PAGES.ROUTE} menu={Menus.ROUTE_MENU}/>))

	/* not found route */
	ui.addRoute("/*", params => ui.changeScreen(<NotFound/>))

	/* Attach keyboard for global key bindings */
	keyboard.attach()

	/* render main index page into dom */
	ReactDOM.render(<Index />, document.getElementById("entry-point"))

	/** session actions catching */
	NotificationCenter.addObserver("login", (user) => {
		if (!user.needToChangePassword) {
			getUserProfileImage();
			setupMenu();
			setupTopMenu();
		}

	})

	/* Avoid going in screens that require login before trying session resume */
	let owner = {}
	SessionStore.subscribe(owner, state => {
	    if (state.resumeComplete) {
	        SessionStore.unsubscribe(owner)
	        ui.startNavigation()
	    }
	})

	/* automatic login, if possible */
	resumeSession()
}
