import React from "react";
import _ from "underscore";
import { isNotEmpty } from "../../framework/utils";
import { optional, uuid } from "../../utils/lang";

export const DROPDOWN_ALIGNMENT = {
    LEFT: "left",
    RIGHT: "right",
    TOP: "top",
    BOTTOM: "bottom",
}

export default function withDropdown(Component, _items, options) {
    return (
        class Dropdown extends React.Component {
            constructor(props) {
                super(props);
                this.uuid = uuid();
            }

            render() {
                const {className: _className, alignment} = options ?? {},
                    className = "dropdown" + (isNotEmpty(_className) ? " " + _className : ""),
                    items = optional(_items, []);

                let dropdownClassName = "dropdown-menu";

                if (isNotEmpty(alignment)) {
                    dropdownClassName += " dropdown-menu-" + alignment;
                }

                return(
                    <div
                        className={className}
                    >
                        <div
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <Component
                                {...this.props}
                            />
                        </div>
                        <div
                            className={dropdownClassName}
                        >
                            {_.map(items, (item, index) => {
                                const key = "dropdown-" + this.uuid + "_item-" + index;
                                return(
                                    <div
                                        key={key}
                                        className="dropdown-item"
                                    >
                                        {item}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )
            }
        }
    )
}