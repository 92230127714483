import M from "../../strings"
import { PAGES } from "../vars"

export default {
    //backUrl: "/",
    items: [    
        {
            id: PAGES.SETTINGS,
            children: [
                {
                    id: "user",
                    icon: "supervisor_account",
                    text: M("users"),
                    href: "/entities/user?grid=users",
                    // permissions: ["user:list"]
                },
                {
                    id: "role",
                    icon: "vpn_key",
                    text: M("roles"),
                    href: "/entities/role?grid=roles",
                    // permissions: ["role:list"]
                },
            ],
        },
    ],
}