import React from "react";
import {CheckCell, DateCell, MultiTextCell, TextCell} from "./components/grids";
import {Image, Mail, ReadOnlyText, Text, YesNo, Number, DROPZONE_MODE_SINGLE, Attachment, DROPZONE_MODE_MULTIPLE, Select, DateTime, TextArea} from "./components/forms";
import {EntitiesLookupContainer, ValuesLookupContainer, ValuesSelectContainer} from "./components/containers";
import { entitySelectContainerField, valuesSelectContainerField } from "./screens/entities/commonFields";
import M from "../strings";
import {getLoggedUser, hasPermission} from "../api/session";
import {resetUserPassword} from "../actions/account";
import * as ui from "./utils/ui";
import {logout} from "../actions/session";
import {activeSearchForm} from "./screens/entities/commonFields";
import { LOCALIZED_VALUE_DESCRIPTOR, SEARCH_FORM_BOOLEAN_DESCRIPTOR, SEARCH_FORM_CODE_DESCRIPTOR, SEARCH_FORM_DATE_DESCRIPTOR, SEARCH_FORM_ENTITY_SELECT_CONTAINER_FIELD_DESCRIPTOR, SEARCH_FORM_NUMBER_DESCRIPTOR, SEARCH_FORM_VALUES_SELECT_CONTAINER_FIELD_DESCRIPTOR } from "../model/searchForms";
import * as datasource from "../utils/datasource";
import { isEmpty } from "../framework/utils";
import { ACTION_TYPES, DEFAULT_ACTIONS } from "./components/actions";
import { alert, confirm, hideLoader, showLoader } from "../plugins";
import ListControl from "./components/custom/ListControl";
import { isNotEmpty } from "../framework/utils";
import * as _query from "../framework/query";
import _ from "underscore";
import AttachmentWithCredits from "./components/custom/attachmentWithCredits";
import { parseBoolean } from "../utils/lang";
import { exportUsers } from "../api/export";
import { LocalizedTextCell } from "./components/customCell/localizedCells";
import { AdvancedLocalizedTextControl, LocalizedText, LocalizedTextArea } from "./components/customControl/localizedControls";
import { getLocalizedValue } from "./utils/localizedObject";

const entities = {
	user: {
		grid: {
			quickSearchEnabled: true,
			title: M("usersList"),
			//subtitle: M("usersListDescription"),
			actions: [
				DEFAULT_ACTIONS.CREATE,
				DEFAULT_ACTIONS.REFRESH,
				DEFAULT_ACTIONS.DELETE,
				DEFAULT_ACTIONS.SELECT_ALL,
				{
					id: "download",
					type: ACTION_TYPES.ICON,
					icon: "download",
					title: M("download"),
					permissions: ["admin:superuser"],
					action: () => {
						showLoader();
						exportUsers()
							.finally(hideLoader)
							.catch(e => {
								alert(M("ooops"), M("problemOccoured"))
							});
					},
				},
			],
			descriptor: {
				columns: [
					{property: "code", header: M("code"), cell: TextCell, sortable: true, searchable: true},
					{property: "name", header: M("name"), cell: TextCell, sortable: true, searchable: true},
					{property: "lastname", header: M("lastname"), cell: TextCell, sortable: true, searchable: true},
					{
						property: "registrationDate", 
						header: M("registrationDate"), 
						cell: DateCell, 
						sortable: true, 
                        searchable: true, 
                        searchForm: SEARCH_FORM_DATE_DESCRIPTOR("registrationDate"),
                    },
					{
						property: "lastLogin", 
						header: M("lastLoginDate"), 
						cell: DateCell, 
						sortable: true, 
                        searchable: true, 
                        searchForm: SEARCH_FORM_DATE_DESCRIPTOR("lastLogin"),
                    },
					{property: "mail", header: M("mail"), cell: TextCell, sortable: true, searchable: true},
					{property: "roles", header: M("roles"), cell: TextCell, sortable: false, searchable: false, props: { formatter: v => v.map(r => r.role).join(", ")}},
					{
						property: "marketingConsent",
						header: M("marketingConsent"),
						cell: CheckCell,
						sortable: true,
						searchable: true,
						searchForm: SEARCH_FORM_BOOLEAN_DESCRIPTOR("marketingConsent")
					},
					{
						property: "notifications",
						header: M("notificationsConsent"),
						cell: CheckCell,
						sortable: true,
						searchable: true,
						searchForm: SEARCH_FORM_BOOLEAN_DESCRIPTOR("notifications")
					},
					{
						property: "active",
						header: M("active"),
						cell: CheckCell,
						sortable: true,
						searchable: true,
						searchForm: activeSearchForm
					}
				]
			}
		},
		form: {
			getTitle(data, params) {
				return [
					{title: M("usersList"), url: "/entities/user" },
					{title: !data || !data.id ? M(["create", "user"]) : M(["edit", "user"]) + " <b>" + data.fullDescription + "</b>"},
				]
			},
			getActions(data) {
				let actions = ["save", "back", "save-go-back", "revisions"];
				if (hasPermission("canResetPassword")) {
					if (data && data.id) {
						actions.push({
							type: ACTION_TYPES.ICON,
							icon: "brush",
							title: "Reset password",
							action: () => {
								confirm(M("confirm"), "Verrà impostata una nuova password ed inviata all'indirizzo mail dell'utente")
									.then((res) => {
										resetUserPassword({id: data.id})
										if (data.id === getLoggedUser().id) {
											confirm(M("confirm"), "La tua password è stata resettata. Dovrai eseguire un nuovo accesso")
												.then((res) => {
													logout();
													ui.navigate("/login")
												})
										}
									})
									.catch((e) => {
										logger.i(e)
									})

							}
						})
					}
				}
				return actions
			},
			descriptor: {
				onModelLoadFirstTime: model => {
					model.on("property:change", (property, value) => {
						if (property === "active"  || property === "roles")  {
							model.invalidateForm()
						}

					})

				},

				areas: [
					{
						title: M("generalInformations"),
						subtitle: null,
						fields: [
							{
								property: "code",
								control: ReadOnlyText,
								label: M("code"),
								placeholder: M("code"),
								size: "col-sm-4"
							},
							{
								property: "name",
								control: Text,
								label: M("name"),
								placeholder: M("name"),
								size: "col-sm-4"
							},
							{
								property: "lastname",
								control: Text,
								label: M("lastname"),
								placeholder: M("lastname"),
								size: "col-sm-4"
							},
							{
								property: "_image",
								control: Image,
								label: M("image")
							},
							{
								property: "notifications",
								control: ReadOnlyText,
								label: M("notificationsConsent"),
								placeholder: M("notificationsConsent"),
								size: "col-sm-6",
								props: {
									formatter: v => parseBoolean(v ?? false) ? M("yes") : M("no"),
								}
							},
							{
								property: "marketingConsent",
								control: ReadOnlyText,
								label: M("marketingConsent"),
								placeholder: M("marketingConsent"),
								size: "col-sm-6",
								props: {
									formatter: v => parseBoolean(v ?? false) ? M("yes") : M("no"),
								}
							},
						]
					},
					{
						title: "Account",
						fields: [
							{
								property: "mail",
								control: Mail,
								label: M("mail"),
								placeholder: M("mailAddress"),
							},
							{
								property: "roles",
								label: M("role"),
								size: "col-12",
								control: EntitiesLookupContainer,
								formatter: v => {
									return v.role
								},
								props: {
									id: "user_roles",
									mode: "multiple",
									entity: "role",
									selectionGrid: {
										columns: [
											{property: "localizedRole", header: M("name"), cell: TextCell}
										]
									},
									popupGrid: {
										columns: [
											{property: "localizedRole", header: M("name"), cell: TextCell}
										]
									}
								}
							},
							{
								property: "active",
								control: YesNo,
								label: M("active"),
								size: "col-12",
							},
						]
					}
				]
			}
		}
	},

	role: {
		grid: {
			title: M("rolesList"),
			//subtitle: M("rolesListDescription"),
			quickSearchEnabled: true,
			descriptor: {
				columns: [
	                {property: "role", header: M("role"), cell: TextCell, sortable: true, searchable: true}
	            ]
			}
		},
		form: {
			title: "Edit role",
			subtitle: null,
			descriptor: {
				showFloatingSaveBtn: true,
				fields: [
					{
                        property: "role",
                        control: Text,
                        label: M("role"),
                        placeholder: M("nameOfRole")
                    },
                    {
                    	property: "_permissions",
                    	label: M("permissions"),
                    	placeholder: M("selectPermissions"),
                    	control: ValuesLookupContainer,
                    	props: {
                    		id: "role_permissions",
                    		collection: "permissions",
	                    	mode: "multiple",
	                        selectionGrid: {
	                            columns: [
	                                {property: "label", header: M("name"), cell: TextCell}
	                            ]
	                        },
	                        popupGrid: {
	                            columns: [
	                                {property: "label", header: M("name"), cell: TextCell}
	                            ]
	                        }
                    	}

					}
				]
			}
		}
	},


    revision: {
        grid: {
            title: M("revisions"),
            descriptor: {
                columns: [
                    {property: "code", header: M("code"), cell: TextCell, sortable: false, searchable: false},
                    {property: "type", header: M("type"), cell: TextCell, sortable: false, searchable: false},
                    {
                        property: "creator",
                        header: M("author"),
                        cell: TextCell,
                        sortable: false,
                        searchable: false
                    },

                    {
                        property: "dateToString",
                        header: M("date"),
                        cell: TextCell,
                        sortable: false,
                        searchable: false
                    },
                    {
                        property: "differences",
                        header: M("differences"),
                        cell: MultiTextCell,
                        sortable: false,
                        searchable: false,
                        props: {
                            singleItemFormatter(v) {
                                let previousValueString = "";
                                let newValueString = "";
                                previousValueString = M("previousValue") + ": " + (v.previousValueDescription? v.previousValueDescription : " null ") + ", ";
                                newValueString = M("newValue") + ": " + (v.newValueDescription? v.newValueDescription : " null ");
                                return M(v.name) + " -> " + previousValueString + newValueString
                            }
                        }
                    }

                ]
            }
        },
	},

	route: {
		grid: {
			title: M("routes"),
			quickSearchEnabled: true,
			descriptor: {
				columns: [
					{
						property: "code",
						header: M("code"),
						cell: TextCell,
						sortable: true,
						searchable: true,
						searchForm: SEARCH_FORM_CODE_DESCRIPTOR({
							isNumberCode: true,
						})
					},
					{	
						property: "name", 
						header: M("name"), 
						cell: LocalizedTextCell, 
						sortable: true, 
						searchable: true, 
						searchForm: LOCALIZED_VALUE_DESCRIPTOR("name"),

					},
					{
						property: "description", 
						header: M("description"), 
						cell: LocalizedTextCell, 
						sortable: true, 
						searchable: true,
						searchForm: LOCALIZED_VALUE_DESCRIPTOR("description"),
					},
					{
						property: "active",
						header: M("active"),
						cell: CheckCell,
						sortable: true,
						searchable: true,
						searchForm: activeSearchForm
					},
				],
			}
		},
		form: {
			dataDescriptionKey: "name",
			descriptor: {
				fields: [
					{
						property: "code",
						label: M("code"),
						control: ReadOnlyText,
						placeholder: M("code"),
						size: "col-sm-6",
						visibility: model => isNotEmpty(model.get("id")),
					},
					{
						property: "name",
						label: M("name"),
						control: LocalizedText,
						placeholder: M("name"),
						size: "col-sm-6",
					},
					{
						property: "description",
						label: M("description"),
						control: LocalizedText,
						placeholder: M("description"),
						size: "col-sm-6",
					},
					{
						property: "active",
						control: YesNo,
						label: M("active"),
						size: "col-sm-6",
					},
				],
			},
		},
	},

	stage: {
		grid: {
			title: M("stages"),
			quickSearchEnabled: true,
			descriptor: {
				columns: [
					{
						property: "code",
						header: M("code"),
						cell: TextCell,
						sortable: true,
						searchable: true,
						searchForm: SEARCH_FORM_CODE_DESCRIPTOR({
							isNumberCode: true,
						})
					},
					{
						property: "title", 
						header: M("title"), 
						cell: LocalizedTextCell, 
						sortable: true, 
						searchable: true,
						searchForm: LOCALIZED_VALUE_DESCRIPTOR("title"),
					},
					{
						property: "description", 
						header: M("description"), 
						cell: LocalizedTextCell, 
						sortable: true, 
						searchable: true,
						searchForm: LOCALIZED_VALUE_DESCRIPTOR("description"),
					},
					{
						property: "latitude",
						header: M("latitude"),
						cell: TextCell,
						sortable: true,
						searchable: true,
						searchForm: SEARCH_FORM_NUMBER_DESCRIPTOR("latitude"),
					},
					{
						property: "longitude",
						header: M("longitude"),
						cell: TextCell,
						sortable: true,
						searchable: true,
						searchForm: SEARCH_FORM_NUMBER_DESCRIPTOR("longitude"),
					},
					{
						property: "altitude",
						header: M(["altitude", "metersUnit"]),
						cell: TextCell,
						sortable: true,
						searchable: true,
						searchForm: SEARCH_FORM_NUMBER_DESCRIPTOR("altitude"),
					},
					{
						property: "active",
						header: M("active"),
						cell: CheckCell,
						sortable: true,
						searchable: true,
						searchForm: activeSearchForm
					},
				],
			},
		},
		form: {
			dataDescriptionKey: "title",
			descriptor: {
				areas: [
					{
						title: M("generalInformations"),
						fields: [
							{
								property: "code",
								label: M("code"),
								control: ReadOnlyText,
								placeholder: M("code"),
								size: "col-sm-6",
								visibility: model => isNotEmpty(model.get("id")),
							},
							{
								property: "title",
								label: M("title"),
								placeholder: M("title"),
								control: LocalizedText,
								size: "col-sm-6",
							},
							{
								property: "description",
								label: M("description"),
								placeholder: M("description"),
								control: LocalizedText,
								size: "col-sm-6",
							},
							{
								property: "latitude",
								label: M("latitude"),
								placeholder: M("latitude"),
								control: Number,
								size: "col-sm-6",
							},
							{
								property: "longitude",
								label: M("longitude"),
								placeholder: M("longitude"),
								control: Number,
								size: "col-sm-6",
							},
							{
								property: "altitude",
								label: M(["altitude", "metersUnit"]),
								placeholder: M("altitude"),
								control: Number,
								size: "col-sm-6",
								props: {
									formatter: v => {
										const parsedValue = parseInt(v);
										return isNaN(parsedValue) ? "" : parsedValue;
									}
								},
							},
							entitySelectContainerField({
								property: "routeId",
								entity: "route",
								label: M("route"),
								mode: "single",
								itemLabel: "label",
								itemValue: "value",
								size: "col-sm-6",
							}),
							{
								property: "active",
								control: YesNo,
								label: M("active"),
								size: "col-sm-6",
							},
						],
					},
					{
						title: M("media"),
						fields: [
							{
								property: "_image",
								label: M("image"),
								control: AttachmentWithCredits,
								props: {
									mode: DROPZONE_MODE_SINGLE,
									acceptedFiles: ".jpg, .jpeg, .png",
								},
								size: "col-12",
							},
							{
								property: "_galleryImages",
								label: M("galleryImages"),
								control: AttachmentWithCredits,
								props: {
									mode: DROPZONE_MODE_MULTIPLE,
									acceptedFiles: ".jpg, .jpeg, .png",
								},
								size: "col-12",
							},
							{
								property: "galleryLinks",
								control: ListControl,
								props: {
									title: M("galleryLinks"),
								},
							},
						],
					},
				],
			},
		},
	},

	routeSection: {
		grid: {
			title: M("routeSections"),
			quickSearchEnabled: true,
			descriptor: {
				columns: [
					{
						property: "code",
						header: M("code"),
						cell: TextCell,
						sortable: true,
						searchable: true,
						searchForm: SEARCH_FORM_CODE_DESCRIPTOR({
							isNumberCode: true,
						})
					},
					{
						property: "order",
						header: M("ordering"),
						cell: TextCell,
						sortable: true,
						searchable: true,
						searchForm: SEARCH_FORM_NUMBER_DESCRIPTOR("order"),
					},
					{
						property: "title", 
						header: M("title"), 
						cell: LocalizedTextCell, 
						sortable: true,
						searchable: true,
						searchForm: LOCALIZED_VALUE_DESCRIPTOR("title"),
					},
					{
						property: "description", 
						header: M("description"), 
						cell: LocalizedTextCell, 
						sortable: true, 
						searchable: true,
						searchForm: LOCALIZED_VALUE_DESCRIPTOR("description"),
					},
					{
						property: "startStage",
						header: M("startStage"),
						cell: TextCell,
						searchable: true,
						props: {
							formatter: v => getLocalizedValue(v?.title)
						},
						searchForm: SEARCH_FORM_ENTITY_SELECT_CONTAINER_FIELD_DESCRIPTOR("startStageId", "stage"),
					},
					{
						property: "finishStage",
						header: M("finishStage"),
						cell: TextCell,
						searchable: true,
						props: {
							formatter: v => getLocalizedValue(v?.title)
						},
						searchForm: SEARCH_FORM_ENTITY_SELECT_CONTAINER_FIELD_DESCRIPTOR("finishStageId", "stage"),
					},
					{
						property: "geographicZone",
						header: M("geographicZone"),
						cell: TextCell,
						searchable: true,
						props: {
							formatter: v => M(v ?? "")
						},
						searchForm: SEARCH_FORM_VALUES_SELECT_CONTAINER_FIELD_DESCRIPTOR("geographicZone", "geographicZone"),
					},
					{
						property: "length",
						header: M(["distance", "kilometersUnit"]),
						cell: TextCell,
						sortable: true,
						searchable: true,
						searchForm: SEARCH_FORM_NUMBER_DESCRIPTOR("length"),
						props: {
							formatter: v => {
								const parsedValue = parseInt(v);
								return isNaN(parsedValue) ? "" : Math.round(parsedValue / 1000);
							}
						},
					},
					{
						property: "minAltitude",
						header: M(["minAltitude", "metersUnit"]),
						cell: TextCell,
						sortable: true,
						searchable: true,
						searchForm: SEARCH_FORM_NUMBER_DESCRIPTOR("minAltitude"),
						props: {
							formatter: v => {
								const parsedValue = parseInt(v);
								return isNaN(parsedValue) ? "" : parsedValue;
							}
						}
					},
					{
						property: "maxAltitude",
						header: M(["maxAltitude", "metersUnit"]),
						cell: TextCell,
						sortable: true,
						searchable: true,
						searchForm: SEARCH_FORM_NUMBER_DESCRIPTOR("maxAltitude"),
						props: {
							formatter: v => {
								const parsedValue = parseInt(v);
								return isNaN(parsedValue) ? "" : parsedValue;
							}
						}
					},
					{
						property: "uphillLength",
						header: M(["uphillLength", "kilometersUnit"]),
						cell: TextCell,
						sortable: true,
						searchable: true,
						searchForm: SEARCH_FORM_NUMBER_DESCRIPTOR("uphillLength"),
						props: {
							formatter: v => {
								const parsedValue = parseInt(v);
								return isNaN(parsedValue) ? "" : Math.round(parsedValue / 1000);
							}
						}
					},
					{
						property: "downhillLength",
						header: M(["downhillLength", "kilometersUnit"]),
						cell: TextCell,
						sortable: true,
						searchable: true,
						searchForm: SEARCH_FORM_NUMBER_DESCRIPTOR("donwhillLength"),
						props: {
							formatter: v => {
								const parsedValue = parseInt(v);
								return isNaN(parsedValue) ? "" : Math.round(parsedValue / 1000);
							}
						}
					},
					{
						property: "uphillHeight",
						header: M(["uphillHeight", "metersUnit"]),
						cell: TextCell,
						sortable: true,
						searchable: true,
						searchForm: SEARCH_FORM_NUMBER_DESCRIPTOR("uphillHeight"),
						props: {
							formatter: v => {
								const parsedValue = parseInt(v);
								return isNaN(parsedValue) ? "" : Math.round(parsedValue);
							}
						}
					},
					{
						property: "downhillHeight",
						header: M(["downhillHeight", "metersUnit"]),
						cell: TextCell,
						sortable: true,
						searchable: true,
						searchForm: SEARCH_FORM_NUMBER_DESCRIPTOR("downhillHeight"),
						props: {
							formatter: v => {
								const parsedValue = parseInt(v);
								return isNaN(parsedValue) ? "" : Math.round(parsedValue);
							}
						}
					},
					{
						property: "active",
						header: M("active"),
						cell: CheckCell,
						sortable: true,
						searchable: true,
						searchForm: activeSearchForm
					},
				],
			},
		},
		form: {
			dataDescriptionKey: "title",
			descriptor: {
				areas: [
					{
						title: M("generalInformations"),
						fields: [
							{
								property: "code",
								label: M("code"),
								control: ReadOnlyText,
								placeholder: M("code"),
								size: "col-sm-6",
								visibility: model => isNotEmpty(model.get("id")),
							},
							{
								property: "order",
								label: M("ordering"),
								placeholder: M("ordering"),
								control: Number,
								props: {
									onlyInteger: true,
								},
								size: "col-sm-6",
							},
							{
								property: "title",
								label: M("title"),
								placeholder: M("title"),
								control: LocalizedText,
								size: "col-sm-6",
							},
							{
								property: "description",
								label: M("description"),
								placeholder: M("description"),
								control: LocalizedText,
								size: "col-sm-6",
							},
							valuesSelectContainerField({
								property: "geographicZone",
								label: M("geographicZone"),
								collection: "geographicZone",
								itemValue: "value",
								itemLabel: "label",
								filterType: "eq",
								size: "col-sm-6",
							}),
							{
								property: "active",
								control: YesNo,
								label: M("active"),
								size: "col-sm-6",
							},
						],
					},
					{
						title: M("route"),
						fields: [
							entitySelectContainerField({
								property: "startStageId",
								label: M("startStage"),
								entity: "stage",
								mode: "single",
								itemValue: "value",
								itemLabel: "label",
								size: "col-sm-6",
							}),
							entitySelectContainerField({
								property: "finishStageId",
								label: M("finishStage"),
								entity: "stage",
								mode: "single",
								itemValue: "value",
								itemLabel: "label",
								size: "col-sm-6",
							}),
							{
								property: "_gpsTrack",
								label: M("gpsTrack"),
								control: Attachment,
								props: {
									mode: DROPZONE_MODE_SINGLE,
									acceptedFiles: ".gpx",
									createImageThumbnails: false,
								},
								size: "col-12",
							},
						],
					},
					{
						title: M("stats"),
						fields: [
							{
								property: "co2Savings",
								label: M(["co2Savings", "kilogramsUnit"]),
								placeholder: M("co2Savings"),
								control: Number,
								size: "col-sm-6",
							},
							{
								property: "length",
								label: M(["distance", "kilometersUnit"]),
								control: ReadOnlyText,
								size: "col-sm-6",
								visibility: model => isNotEmpty(model.get("id")),
								props: {
									formatter: v => {
										const parsedValue = parseInt(v);
										return isNaN(parsedValue) ? "" : Math.round(parsedValue / 1000);
									}
								}
							},
							{
								property: "minAltitude",
								label: M(["minAltitude", "metersUnit"]),
								control: ReadOnlyText,
								size: "col-sm-6",
								visibility: model => isNotEmpty(model.get("id")),
								props: {
									formatter: v => {
										const parsedValue = parseInt(v);
										return isNaN(parsedValue) ? "" : parsedValue;
									}
								}
							},
							{
								property: "maxAltitude",
								label: M(["maxAltitude", "metersUnit"]),
								control: ReadOnlyText,
								size: "col-sm-6",
								visibility: model => isNotEmpty(model.get("id")),
								props: {
									formatter: v => {
										const parsedValue = parseInt(v);
										return isNaN(parsedValue) ? "" : parsedValue;
									}
								}
							},
							{
								property: "uphillLength",
								label: M(["uphillLength", "kilometersUnit"]),
								control: ReadOnlyText,
								size: "col-sm-6",
								visibility: model => isNotEmpty(model.get("id")),
								props: {
									formatter: v => {
										const parsedValue = parseInt(v);
										return isNaN(parsedValue) ? "" : Math.round(parsedValue / 1000);
									}
								}
							},
							{
								property: "downhillLength",
								label: M(["downhillLength", "kilometersUnit"]),
								control: ReadOnlyText,
								size: "col-sm-6",
								visibility: model => isNotEmpty(model.get("id")),
								props: {
									formatter: v => {
										const parsedValue = parseInt(v);
										return isNaN(parsedValue) ? "" : Math.round(parsedValue / 1000);
									}
								}
							},
							{
								property: "uphillHeight",
								label: M(["uphillHeight", "metersUnit"]),
								control: ReadOnlyText,
								size: "col-sm-6",
								visibility: model => isNotEmpty(model.get("id")),
								props: {
									formatter: v => {
										const parsedValue = parseInt(v);
										return isNaN(parsedValue) ? "" : Math.round(parsedValue);
									}
								}
							},
							{
								property: "downhillHeight",
								label: M(["downhillHeight", "metersUnit"]),
								control: ReadOnlyText,
								size: "col-sm-6",
								visibility: model => isNotEmpty(model.get("id")),
								props: {
									formatter: v => {
										const parsedValue = parseInt(v);
										return isNaN(parsedValue) ? "" : Math.round(parsedValue);
									}
								}
							},
						],
					},
				],
			}
		},
	},

	interestPointCategory: {
		grid: {
			title: M("interestPointCategories"),
			quickSearchEnabled: true,
			descriptor: {
				columns: [
					{
						property: "code",
						header: M("code"),
						cell: TextCell,
						sortable: true,
						searchable: true,
						searchForm: SEARCH_FORM_CODE_DESCRIPTOR({
							isNumberCode: true,
						})
					},
					{
						property: "description",
						header: M("description"), 
						cell: LocalizedTextCell, 
						sortable: true, 
						searchable: true,
						searchForm: LOCALIZED_VALUE_DESCRIPTOR("description"),
					},
					{
						property: "active",
						header: M("active"),
						cell: CheckCell,
						sortable: true,
						searchable: true,
						searchForm: activeSearchForm
					},
				],
			},
		},
		form: {
			descriptor: {
				fields: [
					{
						property: "code",
						label: M("code"),
						control: ReadOnlyText,
						placeholder: M("code"),
						size: "col-sm-6",
					},
					{
						property: "description",
						label: M("description"),
						placeholder: M("description"),
						control: LocalizedText,
						size: "col-sm-6",
					},
					{
						property: "_icon",
						label: M("icon"),
						control: Attachment,
						props: {
							mode: DROPZONE_MODE_SINGLE,
							acceptedFiles: ".svg",
						},
						size: "col-6",
					},
					{
						property: "_alternateIcon",
						label: M("alternateIcon"),
						control: Attachment,
						props: {
							mode: DROPZONE_MODE_SINGLE,
							acceptedFiles: ".svg",
						},
						size: "col-6",
					},
					{
						property: "active",
						control: YesNo,
						label: M("active"),
						size: "col-sm-6",
					},
				],
			}
		},
	},

	interestPoint: {
		grid: {
			title: M("interestPoints"),
			quickSearchEnabled: true,
			descriptor: {
				columns: [
					{
						property: "code",
						header: M("code"),
						cell: TextCell,
						sortable: true,
						searchable: true,
						searchForm: SEARCH_FORM_CODE_DESCRIPTOR({
							isNumberCode: true,
						})
					},
					{
						property: "title", 
						header: M("title"), 
						cell: LocalizedTextCell, 
						sortable: true, 
						searchable: true,
						searchForm: LOCALIZED_VALUE_DESCRIPTOR("title"),
					},
					{
						property: "description", 
						header: M("description"), 
						cell: LocalizedTextCell, 
						sortable: true, 
						searchable: true,
						searchForm: LOCALIZED_VALUE_DESCRIPTOR("description"),
					},
					{
						property: "category",
						header: M("category"),
						cell: TextCell,
						sortable: true,
						searchable: true,
						props: {
							formatter: v => getLocalizedValue(v?.title)
						},
						searchForm: SEARCH_FORM_ENTITY_SELECT_CONTAINER_FIELD_DESCRIPTOR("categoryId", "interestPointCategory"),
					},
					{
						property: "latitude",
						header: M("latitude"),
						cell: TextCell,
						sortable: true,
						searchable: true,
						searchForm: SEARCH_FORM_NUMBER_DESCRIPTOR("latitude"),
					},
					{
						property: "longitude",
						header: M("longitude"),
						cell: TextCell,
						sortable: true,
						searchable: true,
						searchForm: SEARCH_FORM_NUMBER_DESCRIPTOR("longitude"),
					},
					{
						property: "altitude",
						header: M(["altitude", "metersUnit"]),
						cell: TextCell,
						sortable: true,
						searchable: true,
						searchForm: SEARCH_FORM_NUMBER_DESCRIPTOR("altitude"),
					},
					{
						property: "active",
						header: M("active"),
						cell: CheckCell,
						sortable: true,
						searchable: true,
						searchForm: activeSearchForm
					},
				],
			},
		},
		form: {
			dataDescriptionKey: "title",
			descriptor: {
				areas: [
					{
						title: M("generalInformations"),
						fields: [
							{
								property: "code",
								label: M("code"),
								control: ReadOnlyText,
								placeholder: M("code"),
								size: "col-sm-6",
								visibility: model => isNotEmpty(model.get("id")),
							},
							{
								property: "title",
								label: M("title"),
								placeholder: M("title"),
								control: LocalizedText,
								size: "col-sm-6",
							},
							{
								property: "description",
								label: M("description"),
								placeholder: M("description"),
								control: LocalizedText,
								size: "col-sm-6",
							},
							{
								property: "latitude",
								label: M("latitude"),
								placeholder: M("latitude"),
								control: Number,
								size: "col-sm-6",
							},
							{
								property: "longitude",
								label: M("longitude"),
								placeholder: M("longitude"),
								control: Number,
								size: "col-sm-6",
							},
							{
								property: "altitude",
								label: M(["altitude", "metersUnit"]),
								placeholder: M("altitude"),
								control: Number,
								size: "col-sm-6",
								props: {
									formatter: v => {
										const parsedValue = parseInt(v);
										return isNaN(parsedValue) ? "" : parsedValue;
									}
								},
							},
							entitySelectContainerField({
								property: "routeId",
								entity: "route",
								label: M("route"),
								mode: "single",
								itemLabel: "label",
								itemValue: "value",
								size: "col-sm-6",
							}),
							entitySelectContainerField({
								property: "categoryId",
								label: M("interestPointCategory"),
								entity: "interestPointCategory",
								mode: "single",
								itemValue: "value",
								itemLabel: "label",
								size: "col-sm-6",
							}),
							{
								property: "active",
								control: YesNo,
								label: M("active"),
								size: "col-sm-6",
							},
						],
					},
					{
						title: M("media"),
						fields: [
							{
								property: "link",
								label: M("link"),
								placeholder: M("link"),
								control: Text,
								size: "col-sm-6",
							},
							{
								property: "linkLabel",
								label: M("linkLabel"),
								placeholder: M("linkLabel"),
								control: Text,
								size: "col-sm-6",
							},
							{
								property: "_image",
								label: M("image"),
								control: AttachmentWithCredits,
								props: {
									mode: DROPZONE_MODE_SINGLE,
									acceptedFiles: ".jpg, .jpeg, .png",
								},
								size: "col-12",
							},
							{
								property: "_galleryImages",
								label: M("galleryImages"),
								control: AttachmentWithCredits,
								props: {
									mode: DROPZONE_MODE_MULTIPLE,
									acceptedFiles: ".jpg, .jpeg, .png",
								},
								size: "col-12",
							},
							{
								property: "galleryLinks",
								control: ListControl,
								props: {
									title: M("galleryLinks"),
								},
							},
						],
					},
				],
			},
		},
	},

	routeInterestPoint: {
		grid: {
			title: M("routeInterestPoints"),
			descriptor: {
				columns: [
					{
						property: "code",
						header: M("code"),
						cell: TextCell,
						sortable: true,
						searchable: true,
						searchForm: SEARCH_FORM_CODE_DESCRIPTOR({
							isNumberCode: true,
						})
					},
					{
						property: "order",
						header: M("ordering"),
						cell: TextCell,
						sortable: true,
						searchable: true,
						searchForm: SEARCH_FORM_NUMBER_DESCRIPTOR("order"),
					},
					{
						property: "routeSection",
						header: M("routeSection"),
						cell: TextCell,
						sortable: true,
						searchable: true,
						props: {
							formatter: v => getLocalizedValue(v?.description)
						},
						searchForm: SEARCH_FORM_ENTITY_SELECT_CONTAINER_FIELD_DESCRIPTOR("routeSectionId", "routeSection"),
					},
					{
						property: "interestPoint",
						header: M("interestPoint"),
						cell: TextCell,
						sortable: true,
						searchable: true,
						props: {
							formatter: v => getLocalizedValue(v?.description)
						},
						searchForm: SEARCH_FORM_ENTITY_SELECT_CONTAINER_FIELD_DESCRIPTOR("interestPointId", "interestPoint"),
					},
					{
						property: "active",
						header: M("active"),
						cell: CheckCell,
						sortable: true,
						searchable: true,
						searchForm: activeSearchForm
					},
				],
			},
		},
		form: {
			dataDescriptionKey: "interestPoint.title",
			descriptor: {
				fields: [
					{
						property: "code",
						label: M("code"),
						control: ReadOnlyText,
						placeholder: M("code"),
						size: "col-sm-6",
						visibility: model => isNotEmpty(model.get("id")),
					},
					{
						property: "order",
						label: M("ordering"),
						placeholder: M("ordering"),
						control: Number,
						props: {
							onlyInteger: true,
						},
						size: "col-sm-6",
					},
					entitySelectContainerField({
						property: "routeSectionId",
						entity: "routeSection",
						label: M("routeSection"),
						mode: "single",
						itemLabel: "label",
						itemValue: "value",
						size: "col-sm-6",
					}),
					entitySelectContainerField({
						property: "interestPointId",
						label: M("interestPoint"),
						entity: "interestPoint",
						mode: "single",
						itemValue: "value",
						itemLabel: "label",
						size: "col-sm-6",
					}),
					{
						property: "active",
						control: YesNo,
						label: M("active"),
						size: "col-sm-6",
					},
				],
			},
		},
	},

	/*stamp: {
		grid: {
			title: M("stamps"),
			canCreate: false,
			canDelete: false,
			descriptor: {
				columns: [
					{
						property: "code",
						header: M("code"),
						cell: TextCell,
						sortable: true,
						searchable: true,
						searchForm: SEARCH_FORM_CODE_DESCRIPTOR({
							isNumberCode: true,
						})
					},
					{
						property: "user",
						header: M("user"),
						cell: TextCell,
						sortable: true,
						searchable: true,
						searchForm: SEARCH_FORM_ENTITY_SELECT_CONTAINER_FIELD_DESCRIPTOR(
							"userId",
							"user"
						),
						props: {
							formatter: v => v?.username ?? "",
						},
					},
					{
						property: "type",
						header: M("type"),
						cell: TextCell,
						searchable: true,
						searchForm: SEARCH_FORM_VALUES_SELECT_CONTAINER_FIELD_DESCRIPTOR("type", "stampType"),
						props: {
							formatter: v => isNotEmpty(v) ? M(v) : "",
						},
					},
					{
						property: "entity",
						header: M("routeSection") + "/" + M("interestPoint"),
						cell: TextCell,
						props: {
							formatter: v => v?.description ?? "",
						},
					},
					{
						property: "active",
						header: M("active"),
						cell: CheckCell,
						sortable: true,
						searchable: true,
						searchForm: activeSearchForm
					},
				],
			},
		},
		form: {
			dataDescriptionKey: "code",
			descriptor: {
				fields: [
					{
						property: "code",
						label: M("code"),
						control: ReadOnlyText,
						placeholder: M("code"),
						size: "col-sm-6",
						visibility: model => isNotEmpty(model.get("id")),
					},
					{
						property: "type",
						label: M("type"),
						control: ReadOnlyText,
						placeholder: M("type"),
						props: {
							formatter: v => isNotEmpty(v) ? M(v) : "",
						},
						size: "col-sm-6",
					},
					{
						property: "user",
						label: M("user"),
						control: ReadOnlyText,
						placeholder: M("user"),
						props: {
							formatter: v => v?.username,
						},
						size: "col-sm-6",
					},
					{
						property: "entity",
						label: M("routeSection") + "/" + M("interestPoint"),
						control: ReadOnlyText,
						placeholder: M("routeSection") + "/" + M("interestPoint"),
						props: {
							formatter: v => v?.description,
						},
						size: "col-sm-6",
					},
					{
						property: "creationDate",
						label: M("creationDate"),
						control: ReadOnlyText,
						placeholder: M("creationDate"),
						props: {
							formatter: v => isNotEmpty(v) ? formatDate(v) : "",
						},
						size: "col-sm-6",
					},
					{
						property: "active",
						control: YesNo,
						label: M("active"),
						size: "col-sm-6",
					},
				],
			},
		},
	},

	favorite: {
		grid: {
			title: M("favorites"),
			canCreate: false,
			canDelete: false,
			descriptor: {
				columns: [
					{
						property: "code",
						header: M("code"),
						cell: TextCell,
						sortable: true,
						searchable: true,
						searchForm: SEARCH_FORM_CODE_DESCRIPTOR({
							isNumberCode: true,
						})
					},
					{
						property: "user",
						header: M("user"),
						cell: TextCell,
						sortable: true,
						searchable: true,
						searchForm: SEARCH_FORM_ENTITY_SELECT_CONTAINER_FIELD_DESCRIPTOR(
							"userId",
							"user"
						),
						props: {
							formatter: v => v?.username ?? "",
						},
					},
					{
						property: "type",
						header: M("type"),
						cell: TextCell,
						searchable: true,
						searchForm: SEARCH_FORM_VALUES_SELECT_CONTAINER_FIELD_DESCRIPTOR("type", "favoriteType"),
						props: {
							formatter: v => isNotEmpty(v) ? M(v) : "",
						},
					},
					{
						property: "entity",
						header: M("routeSection") + "/" + M("interestPoint"),
						cell: TextCell,
						props: {
							formatter: v => v?.description ?? "",
						},
					},
					{
						property: "active",
						header: M("active"),
						cell: CheckCell,
						sortable: true,
						searchable: true,
						searchForm: activeSearchForm
					},
				],
			},
		},
		form: {
			dataDescriptionKey: "code",
			descriptor: {
				fields: [
					{
						property: "code",
						label: M("code"),
						control: ReadOnlyText,
						placeholder: M("code"),
						size: "col-sm-6",
						visibility: model => isNotEmpty(model.get("id")),
					},
					{
						property: "type",
						label: M("type"),
						control: ReadOnlyText,
						placeholder: M("type"),
						props: {
							formatter: v => isNotEmpty(v) ? M(v) : "",
						},
						size: "col-sm-6",
					},
					{
						property: "user",
						label: M("user"),
						control: ReadOnlyText,
						placeholder: M("user"),
						props: {
							formatter: v => v?.username,
						},
						size: "col-sm-6",
					},
					{
						property: "entity",
						label: M("routeSection") + "/" + M("interestPoint"),
						control: ReadOnlyText,
						placeholder: M("routeSection") + "/" + M("interestPoint"),
						props: {
							formatter: v => v?.description,
						},
						size: "col-sm-6",
					},
					{
						property: "creationDate",
						label: M("creationDate"),
						control: ReadOnlyText,
						placeholder: M("creationDate"),
						props: {
							formatter: v => isNotEmpty(v) ? formatDate(v) : "",
						},
						size: "col-sm-6",
					},
					{
						property: "active",
						control: YesNo,
						label: M("active"),
						size: "col-sm-6",
					},
				],
			},
		},
	},*/

	newsCategory: {
		grid: {
			title: M("newsCategories"),
			quickSearchEnabled: true,
			descriptor: {
				columns: [
					{
						property: "code",
						header: M("code"),
						cell: TextCell,
						sortable: true,
						searchable: true,
						searchForm: SEARCH_FORM_CODE_DESCRIPTOR({
							isNumberCode: true,
						})
					},
					{
						property: "description", 
						header: M("description"), 
						cell: LocalizedTextCell, 
						sortable: true, 
						searchable: true,
						searchForm: LOCALIZED_VALUE_DESCRIPTOR("description"),
					},
					{
						property: "active",
						header: M("active"),
						cell: CheckCell,
						sortable: true,
						searchable: true,
						searchForm: activeSearchForm
					},
				],
			},
		},
		form: {
			descriptor: {
				fields: [
					{
						property: "code",
						label: M("code"),
						control: ReadOnlyText,
						placeholder: M("code"),
						size: "col-sm-6",
						visibility: model => isNotEmpty(model.get("id")),
					},
					{
						property: "description",
						label: M("description"),
						placeholder: M("description"),
						control: LocalizedText,
						size: "col-sm-6",
					},
					{
						property: "active",
						control: YesNo,
						label: M("active"),
						size: "col-sm-6",
					},
				],
			}
		},
	},

	news: {
		grid: {
			title: M("news"),
			quickSearchEnabled: true,
			descriptor: {
				columns: [
					{
						property: "code",
						header: M("code"),
						cell: TextCell,
						sortable: true,
						searchable: true,
						searchForm: SEARCH_FORM_CODE_DESCRIPTOR({
							isNumberCode: true,
						})
					},
					{
						property: "title",
						header: M("title"),
						cell: LocalizedTextCell,
						sortable: true,
						searchable: true,
						searchForm: LOCALIZED_VALUE_DESCRIPTOR("title"),
					},
					{
						property: "newsCategories",
						header: M("newsCategories"),
						cell: TextCell,
						searchable: true,
						searchForm: {
							showInCard: false,
							fields: [
								entitySelectContainerField({
									property: "newsCategoriesIds",
									label: M("newsCategories"),
									entity: "newsCategory",
									mode: "multiple",
									itemValue: "value",
									itemLabel: "label",
								}),
								{
									property: "_filterType",
									label: M("filterType"),
									control: Select,
									props: {
										allowNull: false,
										datasource: datasource.fixed([
											{label: M("FILTER_EQ"), value: _query.EQ},
											{label: M("FILTER_IN"), value: _query.IN},
										])
									}
								}
							]
						},
						props: {
							formatter: v => {
								const words =_.chain(v ?? [])
									.filter(i => i?.fullDescription ?? false)
									.pluck("fullDescription")
									.value()
								
								return words.join(", ");
							},
						},
					},
					{
						property: "routes",
						header: M("routes"),
						cell: TextCell,
						searchable: true,
						searchForm: {
							showInCard: false,
							fields: [
								entitySelectContainerField({
									property: "routesIds",
									label: M("routes"),
									entity: "routeSection",
									mode: "multiple",
									itemValue: "value",
									itemLabel: "label",
								}),
								{
									property: "_filterType",
									label: M("filterType"),
									control: Select,
									props: {
										allowNull: false,
										datasource: datasource.fixed([
											{label: M("FILTER_EQ"), value: _query.EQ},
											{label: M("FILTER_IN"), value: _query.IN},
										])
									}
								}
							]
						},
						props: {
							formatter: v => {
								const words =_.chain(v ?? [])
									.filter(i => i?.fullDescription ?? false)
									.pluck("fullDescription")
									.value()
								
								return words.join(", ");
							},
						},
					},
					{
						property: "allRoutes",
						header: M("allRoutes"),
						cell: CheckCell,
						sortable: true,
						searchable: true,
						searchForm: SEARCH_FORM_BOOLEAN_DESCRIPTOR("allRoutes"),
					},
					{
						property: "interestPoints",
						header: M("interestPoints"),
						cell: TextCell,
						searchable: true,
						searchForm: {
							showInCard: false,
							fields: [
								entitySelectContainerField({
									property: "interestPointsIds",
									label: M("interestPoints"),
									entity: "interestPoint",
									mode: "multiple",
									itemValue: "value",
									itemLabel: "label",
								}),
								{
									property: "_filterType",
									label: M("filterType"),
									control: Select,
									props: {
										allowNull: false,
										datasource: datasource.fixed([
											{label: M("FILTER_EQ"), value: _query.EQ},
											{label: M("FILTER_IN"), value: _query.IN},
										])
									}
								}
							]
						},
						props: {
							formatter: v => {
								const words =_.chain(v ?? [])
									.filter(i => i?.title ?? false)
									.pluck("title")
									.map(i => getLocalizedValue(i ?? {}))
									.value()
								
								return words.join(", ");
							},
						},
					},
					{
						property: "allInterestPoints",
						header: M("allInterestPoints"),
						cell: CheckCell,
						sortable: true,
						searchable: true,
						searchForm: SEARCH_FORM_BOOLEAN_DESCRIPTOR("allInterestPoints"),
					},
					{
						property: "stages",
						header: M("stages"),
						cell: TextCell,
						searchable: true,
						searchForm: {
							showInCard: false,
							fields: [
								entitySelectContainerField({
									property: "stagesIds",
									label: M("stages"),
									entity: "stage",
									mode: "multiple",
									itemValue: "value",
									itemLabel: "label",
								}),
								{
									property: "_filterType",
									label: M("filterType"),
									control: Select,
									props: {
										allowNull: false,
										datasource: datasource.fixed([
											{label: M("FILTER_EQ"), value: _query.EQ},
											{label: M("FILTER_IN"), value: _query.IN},
										])
									}
								}
							]
						},
						props: {
							formatter: v => {
								const words =_.chain(v ?? [])
									.filter(i => i?.title ?? false)
									.pluck("title")
									.map(i => getLocalizedValue(i ?? {}))
									.value()
								
								return words.join(", ");
							},
						},
					},
					{
						property: "allStages",
						header: M("allStages"),
						cell: CheckCell,
						sortable: true,
						searchable: true,
						searchForm: SEARCH_FORM_BOOLEAN_DESCRIPTOR("allStages"),
					},
					{
						property: "publicationDate",
						header: M("publicationDate"),
						cell: DateCell,
						sortable: true,
					},
					{
						property: "deactivationDate",
						header: M("deactivationDate"),
						cell: DateCell,
						sortable: true,
					},
					{
						property: "event",
						header: M("event"),
						cell: CheckCell,
						sortable: true,
						searchable: true,
						searchForm: SEARCH_FORM_BOOLEAN_DESCRIPTOR("event"),
					},
					{
						property: "notifications",
						header: M("notifications"),
						cell: CheckCell,
						sortable: true,
						searchable: true,
						searchForm: SEARCH_FORM_BOOLEAN_DESCRIPTOR("notifications"),
					},
					{
						property: "marketing",
						header: M("marketing"),
						cell: CheckCell,
						sortable: true,
						searchable: true,
						searchForm: SEARCH_FORM_BOOLEAN_DESCRIPTOR("marketing"),
					},
					{
						property: "active",
						header: M("active"),
						cell: CheckCell,
						sortable: true,
						searchable: true,
						searchForm: activeSearchForm
					},
				],
			},
		},
		form: {
			dataDescriptionKey: "title",
			descriptor: {
				onModelLoadFirstTime: model => {
					model.on("property:change", (property, value) => {
						if (property === "event"  || property === "notifications")  {
							model.invalidateForm()
						}
					})
				},
				areas: [
					{
						title: M("generalInformations"),
						fields: [
							{
								property: "code",
								label: M("code"),
								control: ReadOnlyText,
								placeholder: M("code"),
								size: "col-sm-6",
								visibility: model => isNotEmpty(model.get("id")),
							},
							{
								property: "title",
								label: M("title"),
								placeholder: M("title"),
								control: LocalizedText,
								size: "col-sm-6",
							},
							entitySelectContainerField({
								property: "newsCategoriesIds",
								label: M("newsCategories"),
								entity: "newsCategory",
								mode: "multiple",
								itemValue: "value",
								itemLabel: "label",
								size: "col-sm-6",
							}),
							{
								property: "event",
								control: YesNo,
								label: M("event"),
								size: "col-sm-6",
							},
							{
								property: "marketing",
								control: YesNo,
								label: M("marketing"),
								size: "col-sm-6",
							},
							{
								property: "active",
								control: YesNo,
								label: M("active"),
								size: "col-sm-6",
							},
							{
								property: "text",
								label: M("text"),
								placeholder: M("text"),
								control: AdvancedLocalizedTextControl,
								size: "col-12",
								props: {
									options: {
										removeButtons: [
											"eraser",
											"classSpan",
											"image",
											"video",
											"file",
											"spellcheck",
											"link",
											"brush",
											"source",
											"fullsize",
										],
									},
								},
							},
						],
					},
					{
						title: M("associations"),
						fields: [
							entitySelectContainerField({
								property: "routesIds",
								label: M("routeSections"),
								entity: "routeSection",
								mode: "multiple",
								itemValue: "value",
								itemLabel: "label",
								size: "col-sm-6",
							}),
							{
								property: "allRoutes",
								control: YesNo,
								label: M("allRoutes"),
								size: "col-sm-6",
							},
							entitySelectContainerField({
								property: "interestPointsIds",
								label: M("interestPoints"),
								entity: "interestPoint",
								mode: "multiple",
								itemValue: "value",
								itemLabel: "label",
								size: "col-sm-6",
							}),
							{
								property: "allInterestPoints",
								control: YesNo,
								label: M("allInterestPoints"),
								size: "col-sm-6",
							},
							entitySelectContainerField({
								property: "stagesIds",
								label: M("stages"),
								entity: "stage",
								mode: "multiple",
								itemValue: "value",
								itemLabel: "label",
								size: "col-sm-6",
							}),
							{
								property: "allStages",
								control: YesNo,
								label: M("allStages"),
								size: "col-sm-6",
							},
						],
					},
					{
						title: M("media"),
						fields: [
							{
								property: "_image",
								label: M("image"),
								control: AttachmentWithCredits,
								props: {
									mode: DROPZONE_MODE_SINGLE,
									acceptedFiles: ".jpg, .jpeg, .png",
								},
								size: "col-12",
							},
							{
								property: "_galleryImages",
								label: M("galleryImages"),
								control: AttachmentWithCredits,
								props: {
									mode: DROPZONE_MODE_MULTIPLE,
									acceptedFiles: ".jpg, .jpeg, .png",
								},
								size: "col-12",
							},
							{
								property: "galleryLinks",
								control: ListControl,
								props: {
									title: M("galleryLinks"),
								},
							},
						],
					},
					{
						title: M("planning"),
						fields: [
							{
								property: "publicationDate",
								label: M("publicationDate"),
								placeholder: M("publicationDate"),
								control: DateTime,
								size: "col-sm-6",
								props: {
									format: "d/m/Y h:i",
									enableTime: true,
								},
							},
							{
								property: "deactivationDate",
								label: M("deactivationDate"),
								placeholder: M("deactivationDate"),
								control: DateTime,
								size: "col-sm-6",
								props: {
									format: "d/m/Y h:i",
									enableTime: true,
									canReset: true,
								},
							},
							{
								property: "eventStartDate",
								label: M("eventStartDate"),
								placeholder: M("eventStartDate"),
								control: DateTime,
								visibility: model => model.get("event"),
								size: "col-sm-6",
								props: {
									format: "d/m/Y h:i",
									enableTime: true,
								},
							},
							{
								property: "eventEndDate",
								label: M("eventEndDate"),
								placeholder: M("eventEndDate"),
								control: DateTime,
								visibility: model => model.get("event"),
								size: "col-sm-6",
								props: {
									format: "d/m/Y h:i",
									enableTime: true,
								},
							},
						],
					},
					{
						title: M("notifications"),
						fields: [
							{
								property: "notifications",
								control: YesNo,
								label: M("notifications"),
								size: "col-sm-6",
							},
							{
								property: "notificationTitle",
								label: M("notificationTitle"),
								placeholder: M("notificationTitle"),
								control: LocalizedText,
								visibility: model => model.get("notifications"),
								size: "col-sm-6",
							},
							{
								property: "notificationText",
								label: M("notificationText"),
								placeholder: M("notificationText"),
								control: LocalizedText,
								visibility: model => model.get("notifications"),
								size: "col-sm-6",
							},
						],
					},
				],
			}
		},
	},

	advice: {
		grid: {
			title: M("advice"),
			quickSearchEnabled: true,
			descriptor: {
				columns: [
					{
						property: "code",
						header: M("code"),
						cell: TextCell,
						sortable: true,
						searchable: true,
						searchForm: SEARCH_FORM_CODE_DESCRIPTOR({
							isNumberCode: true,
						})
					},
					{
						property: "title", 
						header: M("title"), 
						cell: LocalizedTextCell, 
						sortable: true, 
						searchable: true,
						searchForm: LOCALIZED_VALUE_DESCRIPTOR("title"),
					},
					{
						property: "order",
						header: M("ordering"),
						cell: TextCell,
						sortable: true,
						searchable: true,
						searchForm: SEARCH_FORM_NUMBER_DESCRIPTOR("order"),
					},
					{
						property: "active",
						header: M("active"),
						cell: CheckCell,
						sortable: true,
						searchable: true,
						searchForm: activeSearchForm
					},
				],
			},
		},
		form: {
			dataDescriptionKey: "title",
			descriptor: {
				fields: [
					{
						property: "code",
						label: M("code"),
						control: ReadOnlyText,
						placeholder: M("code"),
						size: "col-sm-6",
						visibility: model => isNotEmpty(model.get("id")),
					},
					{
						property: "order",
						label: M("ordering"),
						placeholder: M("ordering"),
						control: Number,
						props: {
							onlyInteger: true,
						},
						size: "col-sm-6",
					},
					{
						property: "title",
						label: M("title"),
						placeholder: M("title"),
						control: LocalizedText,
					},
					{
						property: "text",
						label: M("text"),
						placeholder: M("text"),
						control: LocalizedTextArea,
						size: "col-12",
					},
					{
						property: "active",
						control: YesNo,
						label: M("active"),
						size: "col-sm-6",
					},
				],
			}
		},
	},

	usefulNumber: {
		grid: {
			title: M("usefulNumber"),
			quickSearchEnabled: true,
			descriptor: {
				columns: [
					{
						property: "code",
						header: M("code"),
						cell: TextCell,
						sortable: true,
						searchable: true,
						searchForm: SEARCH_FORM_CODE_DESCRIPTOR({
							isNumberCode: true,
						})
					},
					{ 
						property: "title", 
						header: M("title"), 
						cell: LocalizedTextCell, 
						sortable: true, 
						searchable: true,
						searchForm: LOCALIZED_VALUE_DESCRIPTOR("title"),
					},
					{
						property: "description",
						header: M("description"),
						cell: LocalizedTextCell,
						sortable: true,
						searchable: true,
						searchForm: LOCALIZED_VALUE_DESCRIPTOR("description"),
					},
					{
						property: "stage",
						header: M("stage"),
						cell: TextCell,
						searchable: true,
						props: {
							formatter: v => getLocalizedValue(v?.title)
						},
						searchForm: SEARCH_FORM_ENTITY_SELECT_CONTAINER_FIELD_DESCRIPTOR("stageId", "stage"),
					},
					{
						property: "active",
						header: M("active"),
						cell: CheckCell,
						sortable: true,
						searchable: true,
						searchForm: activeSearchForm
					},
				],
			},
		},
		form: {
			descriptor: {
				areas: [
					{
						title: M("generalInformations"),
						fields: [
							{
								property: "code",
								label: M("code"),
								control: ReadOnlyText,
								placeholder: M("code"),
								size: "col-sm-6",
								visibility: model => isNotEmpty(model.get("id")),
							},
							{
								property: "title",
								label: M("title"),
								placeholder: M("title"),
								control: LocalizedText,
								size: "col-sm-6",
							},
							{
								property: "description",
								label: M("description"),
								placeholder: M("description"),
								control: LocalizedText,
								size: "col-sm-6",
							},
							entitySelectContainerField({
								property: "stageId",
								label: M("stage"),
								entity: "stage",
								mode: "single",
								itemValue: "value",
								itemLabel: "label",
								size: "col-sm-6",
							}),
							{
								property: "active",
								control: YesNo,
								label: M("active"),
								size: "col-sm-6",
							},
						],
					},
					{
						title: M("contacts"),
						fields: [
							{
								property: "link",
								label: M("link"),
								placeholder: M("link"),
								control: Text,
								size: "col-sm-6",
							},
							{
								property: "linkLabel",
								label: M("linkLabel"),
								placeholder: M("linkLabel"),
								control: Text,
								size: "col-sm-6",
							},
							{
								property: "phoneNumber1",
								label: M("phoneNumber") + " 1",
								placeholder: M("phoneNumber") + " 1",
								control: Text,
								size: "col-sm-6",
							},
							{
								property: "phoneNumber2",
								label: M("phoneNumber") + " 2",
								placeholder: M("phoneNumber") + " 2",
								control: Text,
								size: "col-sm-6",
							},
							{
								property: "phoneNumber3",
								label: M("phoneNumber") + " 3",
								placeholder: M("phoneNumber") + " 3",
								control: Text,
								size: "col-sm-6",
							},
						],
					},
				],
			}
		},
	},

	routeItemCategory: {
		grid: {
			title: M("routeItemCategories"),
			quickSearchEnabled: true,
			descriptor: {
				columns: [
					{
						property: "code",
						header: M("code"),
						cell: TextCell,
						sortable: true,
						searchable: true,
						searchForm: SEARCH_FORM_CODE_DESCRIPTOR({
							isNumberCode: true,
						})
					},
					{	
						property: "name", 
						header: M("name"), 
						cell: LocalizedTextCell, 
						sortable: true, 
						searchable: true, 
						searchForm: LOCALIZED_VALUE_DESCRIPTOR("name"),

					},
					{
						property: "description", 
						header: M("description"), 
						cell: LocalizedTextCell, 
						sortable: true, 
						searchable: true,
						searchForm: LOCALIZED_VALUE_DESCRIPTOR("description"),
					},
					{
						property: "type", 
						header: M("type"), 
						cell: TextCell, 
						sortable: true, 
						searchable: true,
						props:{
							formatter: v => v && M(v),
						}
					},
					{
						property: "list", 
						header: M("isList"), 
						cell: CheckCell, 
						sortable: true, 
						searchable: true,
					},
					{
						property: "active",
						header: M("active"),
						cell: CheckCell,
						sortable: true,
						size: "col-sm-6",
					},
				]
			},
		},
		form: {
			descriptor: {
				onModelLoadFirstTime: model => {
					model.on("property:change", (property, value) => {
						if(property === "type") {
							console.log(model);
							model.set("entities", []);
							model.set("entityIds", []);
							model.set("entityType", "")
							model.invalidateForm();
						}
					})
				},
				areas: [
					{
						title: M("generalInformations"),
						fields: [
							{
								property: "code",
								label: M("code"),
								control: ReadOnlyText,
								placeholder: M("code"),
								size: "col-sm-6",
								visibility: model => isNotEmpty(model.get("id")),
							},
							{
								property: "name",
								label: M("name"),
								placeholder: M("name"),
								control: LocalizedText,
								size: "col-sm-6",
							},
							{
								property: "description",
								label: M("description"),
								placeholder: M("description"),
								control: LocalizedText
							},
						]
					},
					{
						title: M("addElementsToRouteItemCategory"),
						fields: [
							valuesSelectContainerField({
								id: "valuesSelect_routeItemType",
								property: "type",
								label: M("type"),
								collection: "routeItemCategoryContent",
								mode: "single",
								itemValue: "value",
								itemLabel: "label",
								filterType: "eq",
							}),
							entitySelectContainerField({
								id: "entitySelect_interestPoint",
								key: "entitySelect_interestPoint",
								property: "entityIds",
								label: M("interestPoint"),
								entity: "interestPoint",
								mode: "multiple",
								itemValue: "value",
								itemLabel: "label",
								visibility: model => model.get("type") === "interestPoint"
							}),
							entitySelectContainerField({
								id: "entitySelect_routeSection",
								key: "entitySelect_routeSection",
								property: "entityIds",
								label: M("routeSection"),
								entity: "routeSection",
								mode: "multiple",
								itemValue: "value",
								itemLabel: "label",
								visibility: model => model.get("type") === "routeSection"
							}),
							
						]	
					},
					{
						title: M("generalSettings"),
						fields: [
							{
								property: "list",
								control: YesNo,
								label: M("shownAsList"),
								size: "col-sm-6",
							},
							{
								property: "active",
								control: YesNo,
								label: M("active"),
								size: "col-sm-6",
							},
						]
					}
				]
			}
		}
	}
}

export function getEntityData(entityName) {
    const data = entities[entityName];
    if (data) {
        return data;
    } else {
        new Error("[entities] getData: could not find entity data");
    }
}

export function withEntityDescriptor(Component) {
    class WrappedComponent extends React.Component {

        getEntityData() {
            const entity = this.props.entity;
            if (isEmpty(entity)) {
                throw new Error("[entities] withEntityDescriptor: entity prop is not defined")
            }
            return getEntityData(entity);
        }

        render() {
            const descriptorData = this.getEntityData(),
                entityGrid = descriptorData.grid,
                entityForm = descriptorData.form;

            return(
                <Component
                    entityGrid={entityGrid}
                    entityForm={entityForm}
                    {...this.props}
                />
            )
        }
    }

    return WrappedComponent
}