import React from "react";
import ReactDOM from "react-dom";
import {optional, parseBoolean, uuid} from "../../utils/lang";
import _ from "underscore";
import { isNotEmpty } from "../../framework/utils";


export class MaterialIcon extends React.Component {
    constructor(props) {
        super(props)
    }

    onClick(e) {
        if (_.isFunction(this.props.onClick))
            this.props.onClick(e);
    }

    render() {
        const style = optional(this.props.style, {}),
            icon = this.props.icon,
            rounded = parseBoolean(optional(this.props.rounded, true)),
            twoTone = parseBoolean(optional(this.props.twoTone, false)),
            hasOnClick = this.props.onClick && _.isFunction(this.props.onClick);

        let className = "material-icons";
        if (rounded) {
            className += "-round";
        } else if (twoTone) {
            className += "-two-tone";
        }
        if (isNotEmpty(this.props.className)) {
            className += " " + this.props.className;
        }

        if (hasOnClick) {
            className += " pointer-cursor";
        }
        
        return (
            <i
                {...this.props}
                onClick={this.onClick.bind(this)}
                style={style}
                className={className}
            >
                {icon}
            </i>
        )
    }
}

export class MaterialIconWithTooltip extends React.Component {
    componentDidMount() {
        $(ReactDOM.findDOMNode(this)).tooltip({trigger: "hover"});
    }

    componentWillUnmount() {
        $(ReactDOM.findDOMNode(this)).tooltip("dispose");
    }

    render() {
        const title = this.props.title ?? M("tooltip");

        return (
            <MaterialIcon
                {...this.props}
                title={title}
                data-placement="bottom"
                data-toggle="tooltip"
            />
        )
    }
}