"use strict"

export const UI = "UI";
export const SESSION = "SESSION";
export const ACCOUNT = "ACCOUNT";
export const GRIDS = "GRIDS"
export const ENTITIES = "ENTITIES"
export const LOOKUP = "LOOKUP"
export const MENU = "MENU"
export const SELECT = "SELECT"
export const MULTIVALUE_SETTINGS = "MULTIVALUE_SETTINGS"
export const SYSTEM = "SYSTEM"
export const TABS = "TABS"
export const SEARCH = "SEARCH"
export const DIALOG = "DIALOG"
