"use strict"

export const LOGIN = "LOGIN";
export const RESUME_SESSION = "RESUME_SESSION"
export const LOGOUT = "LOGOUT";
export const REGISTER = "REGISTER";
export const RECOVER_ACCOUNT = "RECOVER_ACCOUNT"
export const REQUEST_RECOVERY_CODE = "REQUEST_RECOVERY_CODE"
export const RESET_PASSWORD = "RESET_PASSWORD"
export const VALIDATE_RECOVERY_CODE = "VALIDATE_RECOVERY_CODE"
export const SET_ACTIVATION_CODE = "SET_ACTIVATION_CODE"
export const CONFIRM_ACCOUNT = "CONFIRM_ACCOUNT"

export const GET_GRID = "GET_GRID"
export const LOAD_ENTITIES = "LOAD_ENTITIES"
export const DELETE_ENTITIES = "DELETE_ENTITIES"
export const SAVE_ENTITY = "SAVE_ENTITY"
export const NEW_ENTITY = "NEW_ENTITY"
export const GET_ENTITY = "GET_ENTITY"
export const FREE_ENTITIES = "FREE_ENTITIES"
export const GET_LOOKUP_RESULT = "GET_LOOKUP_RESULT"
export const GET_LOOKUP_VALUES = "GET_LOOKUP_VALUES"
export const FREE_LOOKUP = "FREE_LOOKUP"
export const GET_SELECT_ENTITIES = "GET_SELECT_ENTITIES"
export const GET_SELECT_VALUES = "GET_SELECT_VALUES"
export const FREE_SELECT = "FREE_SELECT"

export const SETUP_MENU = "SETUP_MENU"
export const SETUP_CUSTOM_MENU = "SETUP_CUSTOM_MENU"
export const SETUP_TOP_MENU = "SETUP_TOP_MENU"

export const GET_USER_COVER_IMAGE = "GET_USER_COVER_IMAGE"
export const GET_USER_PROFILE_IMAGE = "GET_USER_PROFILE_IMAGE"

export const RESET_PASSWORD_CHANGE = "RESET_PASSWORD_CHANGE"
export const RESET_USER_PASSWORD = "RESET_USER_PASSWORD"
export const CHANGE_PASSWORD = "CHANGE_PASSWORD"
export const FREE_SETTINGS_VALUES = "FREE_SETTINGS_VALUES"
export const UPDATE_MULTIVALUE_SETTINGS = "UPDATE_MULTIVALUE_SETTINGS"
export const SET_MULTIVALUE_SETTINGS = "SET_MULTIVALUE_SETTINGS"
export const CHECK_REVISION_ENABLE_STATUS = "CHECK_REVISION_ENABLE_STATUS"

export const SYSTEM_INFORMATIONS = "SYSTEM_INFORMATIONS"

export const GET_ALL_ORGANIZATIONS = "GET_USER_ORGANIZATIONS"
export const CREATE_ORGANIZATION = "CREATE_ORGANIZATION"
export const SELECT_ORGANIZATION = "SELECT_ORGANIZATION"
export const SHOW_ORGANIZATION_SELECTION_DIALOG = "SHOW_ORGANIZATION_SELECTION_DIALOG"
export const HIDE_ORGANIZATION_SELECTION_DIALOG = "HIDE_ORGANIZATION_SELECTION_DIALOG"
export const FIND_FREE_ORGANIZATION_NAME = "FIND_FREE_ORGANIZATION_NAME"

export const FIND_NODES = "FIND_NODES"
export const SET_CONFIGURATION = "SET_CONFIGURATION"
export const REQUEST_STATE_UPDATE = "REQUEST_STATE_UPDATE"
export const SET_STATE = "SET_STATE"

export const SET_SELECTED_TAB = "SET_SELECTED_TAB";
export const CLEAR_TAB_STATE = "CLEAR_TAB_STATE";

export const GET_QUERY = "GET_QUERY"
export const UPDATE_QUERY = "UPDATE_QUERY";
export const UPDATE_FORM_DATA = "UPDATE_FORM_DATA";

export const SHOW_DIALOG = "SHOW_DIALOG"
export const UPDATE_DIALOG = "UPDATE_DIALOG"
export const HIDE_DIALOG = "HIDE_DIALOG"
export const ON_DIALOG_HIDDEN = "ON_DIALOG_HIDDEN"
export const CLEAN_DIALOG_QUEUE = "CLEAN_DIALOG_QUEUE"