import React from "react"
import {connect} from "../utils/aj"
import Login from "../screens/login"
import ChangePassword from "../screens/changePassword";
import Forbidden from "../screens/forbidden";
import {SessionStore} from "../../stores/session";
import _ from "underscore";
import * as Session from "../../api/session";
import NotFound from "../screens/notFound";

const OBSCURE_ROUTING = true;

export default class Secure extends React.Component {

    constructor(props) {
        super(props)

        connect(this, SessionStore)
    }

    getContent() {
        const isLoggedIn = this.state.isLoggedIn,
            user = this.state.user || {};
        let requiredPermissions = this.props.permissions || [];

        if (!_.isArray(requiredPermissions)) {
            requiredPermissions = [requiredPermissions];
        }

        if (!isLoggedIn) {
            return <Login/>
        } else if (user && user.needToChangePassword) {
            return <ChangePassword/>
        } else if (_.some(requiredPermissions, permission => !Session.hasPermission(permission))) {
            return OBSCURE_ROUTING ? <NotFound/> : <Forbidden/>
        } else {
            return this.props.children
        }
    }

    render() {
        let toPrint = this.getContent();
        return (
            (
                toPrint
            )
        )
    }

}

//To be used in selected scenarios in which we need to encapsulate the whole component and not just the output of it's render method. E.g. EntityForm class
export function secureScreen(Component, permissions) {
    function WrappedComponent(props) {
        return (
            <Secure
                permissions={permissions}
            >
                <Component {...props}/>
            </Secure>
        )
    }

    return WrappedComponent;
}
