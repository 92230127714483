"use strict"

import * as aj from "../aj/index";
import * as actions from "../actions/types";
import _ from "underscore";
import {walk} from "../utils/lang";
import {MENU} from "./types";
import * as MenuUtils from "../utils/menu";
import {deepClone} from "../framework/utils";

export function defaultMenuState() {
    return {
        menu: {},
        topMenu: {},
    }
}

export const MenuStore = aj.createStore(MENU, (state = defaultMenuState(), action) => {
    let _menu;

    switch(action.type) {
        case actions.SETUP_MENU:
        case actions.SETUP_CUSTOM_MENU:
            let menu = MenuUtils.sanitizeMenu(action.menu);
            return _.assign(state, {menu: menu});

        case actions.SETUP_TOP_MENU:
            let topMenu = MenuUtils.sanitizeMenu(action.menu);
            return _.assign(state, {topMenu: topMenu});
    }
})

