import { PAGES } from "../vars";
import M from "../../strings";

export default {
    items: [
        /*{
            id: PAGES.HOME,
            icon: "home",
            text: M(PAGES.HOME),
            href: "",
        },*/
        {
            id: PAGES.ROUTE,
            icon: "alt_route",
            text: M(PAGES.ROUTE),
            href: "/route",
        },
        {
            id: PAGES.NEWS,
            icon: "newspaper",
            text: M(PAGES.NEWS),
            href: "/news",
        },
        /*{
            id: PAGES.FAVORITES,
            icon: "favorite",
            text: M(PAGES.FAVORITES),
            href: "/entities/favorite",
        },
        {
            id: PAGES.VISITED,
            icon: "check_circle",
            text: M(PAGES.VISITED),
            href: "/entities/stamp",
        },*/
        {
            id: PAGES.SETTINGS,
            icon: "settings",
            text: M(PAGES.SETTINGS),
            href: "/settings",
        },
    ]
}