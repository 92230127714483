import _ from "underscore";
import { EQ, GTE, LIKE, LTE } from "../framework/query";
import M from "../strings";
import { DateTime, Number, Select, Text, YesNo } from "../web/components/forms";
import * as datasource from "../utils/datasource";
import { optional } from "../utils/lang";
import { entitySelectContainerField, valuesSelectContainerField } from "../web/screens/entities/commonFields";

export const SEARCH_FORM_BOOLEAN_DESCRIPTOR = (propertyName) => {
    return {
        showInCard: false,
        fields: [
            {
                property: propertyName,
                label: M("value"),
                control: Select,
                filterType: EQ,
                props: {
                    allowNull: true,
                    datasource: datasource.fixed([
                        {label: M("yes"), value: true},
                        {label: M("no"), value: false},
                    ]),
                }
            }
        ]
    }
}

export const SEARCH_FORM_CODE_DESCRIPTOR = (option) => {
    let controlType = optional(option, {}).isNumberCode ? Number : Text
    return {
        showInCard: false,
        fields: [
            {
                property: "code",
                label: M("value"),
                control: controlType,
                filterType: EQ,
            },
        ]
    }
}

export const SEARCH_FORM_DATE_DESCRIPTOR = (propertyName) => _.assign({}, {
    showInCard: false,
    fields: [
        {
            property: propertyName,
            label: M("value"),
            control: DateTime,
        },
        {
            property: "_filterType",
            label: M("filterType"),
            control: Select,
            props: {
                allowNull: false,
                datasource: datasource.fixed([
                    {label: M("FILTER_GTE"), value: GTE},
                    {label: M("FILTER_LTE"), value: LTE}
                ])
            }
        }
    ]
})

export const SEARCH_FORM_NUMBER_DESCRIPTOR = (propertyName) => _.assign({}, {
    showInCard: false,
    fields: [
        {
            property: propertyName,
            label: M("value"),
            control: Number,
        },
        {
            property: "_filterType",
            label: M("filterType"),
            control: Select,
            props: {
                allowNull: false,
                datasource: datasource.fixed([
                    {label: M("FILTER_EQ"), value: EQ},
                    {label: M("FILTER_GTE"), value: GTE},
                    {label: M("FILTER_LTE"), value: LTE}
                ])
            }
        }
    ]
})

export const SEARCH_FORM_ENTITY_SELECT_CONTAINER_FIELD_DESCRIPTOR = (propertyName, entity, options = {}) => {
    const _options = _.assign(
        {},
        {
            label: M("value"),
            mode: "single",
            itemLabel: "label",
            itemValue: "value",
        },
        options,
        {
            property: propertyName,
            entity: entity,
            allowNull: true,
        },
    )

    return _.assign({}, {
        showInCard: false,
        fields: [
            entitySelectContainerField(_options),
        ]
    })
}

export const SEARCH_FORM_VALUES_SELECT_CONTAINER_FIELD_DESCRIPTOR = (propertyName, collection, options = {}) => {
    const _options = _.assign(
        {},
        {
            label: M("value"),
            mode: "single",
            itemLabel: "label",
            itemValue: "value",
        },
        options,
        {
            property: propertyName,
            collection: collection,
            allowNull: true,
        },
    )

    return _.assign({}, {
        showInCard: false,
        fields: [
            valuesSelectContainerField(_options),
        ]
    })
}

export function LOCALIZED_VALUE_DESCRIPTOR(propertyName) {
    const _propertyName = propertyName + ".values.value";

    return {
        showInCard: false,
        fields: [
            {
                property: _propertyName,
                label: M("value"),
                control: Text,
                filterType: LIKE,
            },
        ]
    }
}