import React from "react";
import _ from "underscore";
import { MaterialIcon, MaterialIconWithTooltip } from "./materialIcon";
import { isNotEmpty } from "../../framework/utils";
import { hasPermission } from "../../api/session";
import { optional, parseBoolean, uuid } from "../../utils/lang";
import withDropdown from "./dropdown";

export const DEFAULT_ACTIONS = {
    REFRESH: "refresh",
    CREATE: "create",
    DELETE: "delete",
    SELECT_ALL: "selectAll",
    SAVE: "save",
    BACK: "back",
    REVISIONS: "revisions",
}

export const ACTION_TYPES = {
    ICON: "icon",
    DROPDOWN: "dropdown",
    BUTTON: "button",
    CUSTOM: "custom",
}

export class ActionsMatcher {
    constructor(defaultActions) {
        this.defaultActions = defaultActions
    }

    match(userActions) {
        let actions = []

        if (userActions) {
            if (!_.isArray(userActions)) {
                throw new Error("grid.actions must be an array but is " + userActions)
            }

            _.each(userActions, a => {
                if (_.isObject(a)) {
                    actions.push(a)
                } else if (typeof a === "string") {
                    let defaultAction = _.find(this.defaultActions, d => d.id === a)
                    if (!_.isEmpty(defaultAction)) {
                        actions.push(defaultAction)
                    } else {
                        logger.w("Default action not found: " + a)
                    }
                }
            })
        } else {
            actions = this.defaultActions
        }

        return actions;
    }
}

export class Actions extends React.Component {
    constructor(props) {
        super(props);
        this.uuid = uuid();
    }

    mapActions() {
        const actions = _.filter(optional(this.props.actions, []), a => hasPermission(a.permissions ?? []) === true);
        return _.map(actions, (a, index) => {
            const key = "actions-" + this.uuid + "_action-" + a.id;
            
            return (<Action
                        key={key}
                        {...a}
                    />);
        })
    }

    render() {
        const className = "actions" + (isNotEmpty(this.props.className) ? " " + this.props.className : ""),
            actions = this.mapActions();
        return(
            <div
                className={className}
            >
                {actions}
            </div>
        )
    }
}

class Action extends React.Component {
    getComponent() {
        const {type, component} = this.props;
        switch (type) {
            
            case ACTION_TYPES.CUSTOM:
                return component;
            case ACTION_TYPES.BUTTON:
                return ButtonAction;
            case ACTION_TYPES.DROPDOWN:
                return DropdownActionButton;
            case ACTION_TYPES.ICON:
            default:
                return TooltipActionButton;
        }
    }

    sanitizeProps() {
        const {type, className} = this.props;
        switch(type) {
            case ACTION_TYPES.CUSTOM:
                return _.extend(
                        {},
                        this.props,
                        {
                            className: isEmpty(className) ? "actions__item" : className + " actions__item"
                        }
                    )
            default:
                return {...this.props};
        }
    }

    render() {
        const Component = this.getComponent(),
            _props = this.sanitizeProps();

        return (
            <Component
                {..._props}
            />
        )
    }
}

export class TooltipActionButton extends React.Component {
    isDisabled() {
        return parseBoolean(optional(this.props.disabled, false))
    }

    onItemClick() {
        if (this.isDisabled()) {
            return false;
        }

        const action = this.props.action,
            _arguments = this.props.arguments;

        if (action && _.isFunction(action)) {
            action.apply(this, _arguments);
        }
    }

    getComponent() {
        const title = this.props.title;

        return isNotEmpty(title) ? MaterialIconWithTooltip : MaterialIcon;
    }

    render() {
        const Component = this.getComponent(),
            className = "actions__item" + (isNotEmpty(this.props.className) ? " " + this.props.className : ""),
            _props = _.omit(this.props, "action", "arguments");
        return(
            <Component
                {..._props}
                className={className}
                onClick={this.onItemClick.bind(this)}
            />
        )
    }
}

class DropdownActionButton extends React.Component {
    constructor(props) {
        super(props);
        this.uuid = uuid();
    }

    onItemClick(item) {
        const action = item.action,
            _arguments = item.arguments;

        if (action && _.isFunction(action)) {
            action.apply(this, _arguments);
        }
    }

    getItems() {
        const items = _.filter(optional(this.props.items, []), item => hasPermission(item.permissions) === true);
        return _.map(items, item => {
            const {icon, title} = item;

            return(
                <div
                    onClick={this.onItemClick.bind(this, item)}
                    className="actions__dropdown-item"
                >
                    <MaterialIcon
                        icon={icon}
                    />
                    {title}
                </div>
            )
        })
    }

    render() {
        const title = this.props.title,
            items = this.getItems(),
            className = "actions__item actions__item--dropdown" + (isNotEmpty(this.props.className) ? " " + this.props.className : ""),
            Component = withDropdown(isNotEmpty(title) ? MaterialIconWithTooltip : MaterialIcon, items);
        
        return (
            <div
                className={className}
            >
                <Component
                    {..._.omit(this.props,"items", "icon", "className")}
                    icon={this.props.icon || "more_vert"}
                    className="actions__dropdown-trigger"
                />
            </div>
        )
    }
}

class ButtonAction extends React.Component {

    isDisabled() {
        return parseBoolean(optional(this.props.disabled, false))
    }
    
    onItemClick() {
        if (this.isDisabled()) {
            return false;
        }

        const action = this.props.action,
            _arguments = this.props.arguments;

        if (action && _.isFunction(action)) {
            action.apply(this, _arguments);
        }
    }

    render() {
        const text = this.props.title,
            disabled = this.isDisabled(),
            className = "actions__item actions__item--btn btn" + (isNotEmpty(this.props.className) ? " " + this.props.className : " btn-primary");
        return (
            <button
                disabled={disabled}
                type="button"
                className={className}
                onClick={this.onItemClick.bind(this)}
            >
                {text}
            </button>
        )
    }
}