import {addToken} from "./utils";
import * as config from "../framework/config";

export function exportUsers() {
    const url = config.get("service.url") + "export/users",
        headers = {};

    addToken(headers);

    return fetch(
        url,
        {
           method: "GET" ,
           headers: headers,
        }
    )
        .then(response => {
            if (response.ok) {
                return response.blob();
            } else {
                throw new Error(`HTTP error! Status: ${ response.status }`);
            }
        })
        .then(blob => {
            const now = new Date(),
                fileName = now.getTime() + ".xlsx",
                file = blob,
                link = document.createElement('a');

            link.style.display = 'none';
            link.download = fileName;
            link.href =window.URL.createObjectURL(file);
            link.click();
            link.remove();
        });
}