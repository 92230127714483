import M from "../../strings";
import * as Menus from "./types";

export default {
    [Menus.ROUTE_MENU]: {
        items: [
            {
                id: "route",
                children: [
                    {
                        id: "route",
                        icon: "alt_route",
                        text: M("routes"),
                        href: "/route/route",
                    },
                    {
                        id: "stage",
                        icon: "where_to_vote",
                        text: M("stages"),
                        href: "/route/stage",
                    },
                    {
                        id: "routeSection",
                        icon: "turn_sharp_right",
                        text: M("routeSections"),
                        href: "/route/routeSection",
                    },
                    {
                        id: "routeInterestPoint",
                        icon: "place",
                        text: M("routeInterestPoints"),
                        href: "/route/routeInterestPoint",
                    },
                ],
            },
            {
                id: "interestPoints",
                children: [
                    {
                        id: "interestPointCategory",
                        icon: "category",
                        text: M("interestPointCategories"),
                        href: "/route/interestPointCategory",
                    },
                    {
                        id: "interestPoint",
                        icon: "not_listed_location",
                        text: M("interestPoints"),
                        href: "/route/interestPoint",
                    },
                ],
            },
            {
                id: "routeItemCategory",
                children: [
                    {
                        id: "routeItemCategory",
                        icon: "menu_open",
                        text: M("routeItemCategories"),
                        href: "/route/routeItemCategory"
                    }
                ]
            },
            {
                id: "usefulNumbers",
                children: [
                    {
                        id: "usefulNumber",
                        icon: "phone",
                        text: M("usefulNumbers"),
                        href: "/route/usefulNumber",
                    },
                ],
            },
        ],
    },
    [Menus.NEWS_MENU]: {
        items: [
            {
                id: "news",
                children: [
                    {
                        id: "newsCategory",
                        icon: "category",
                        text: M("newsCategories"),
                        href: "/news/newsCategory",
                    },
                    {
                        id: "news",
                        icon: "newspaper",
                        text: M("news"),
                        href: "/news/news",
                    },
                ],
            },
            {
                id: "advices",
                children: [
                    {
                        id: "advice",
                        icon: "info",
                        text: M("advices"),
                        href: "/news/advice",
                    },
                ],
            },
        ],
    },
};