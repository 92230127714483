import React from "react";
import { Screen, FullScreenLayout, Header } from "../components/layout";
import { isNotEmpty } from "../../framework/utils";
import M from "../../strings";
import { MaterialIcon } from "../components/materialIcon";
import { isLoggedIn } from "../../api/session";

export default class NotFound extends Screen {

    render() {
        const content = this.props.content;

        return(
            <FullScreenLayout>
                {isLoggedIn() && <Header
                    noMenu={true}
                />}
                {isNotEmpty(content) ? (
                    {content}
                ) : (
                    <section
                        className="error"
                    >
                        <div
                            className="error__inner"
                        >
                            <h1>
                                <MaterialIcon
                                    className="fs-72 text-primary"
                                    icon="error"
                                />
                            </h1>
                            <h2>
                                {M("notFoundScreenGenericMessage")}
                            </h2>
                        </div>
                    </section>
                )}
            </FullScreenLayout>
        )
    }
}